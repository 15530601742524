import React, { Fragment } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {withTranslation} from "react-i18next";
import PropTypes from "prop-types";
import MultiRangeSlider from "../../multiRangeSlider/MultiRangeSlider";
class PageSelect extends React.Component {
	constructor(props) {
		super(props);
	}
	state = {
		open:false
	};
	
	async componentDidMount() {
	
	}
	render() {
		return (
			<>
			<div onClick={(e)=>{
				this.setState({open:true})
			}}>Тут селект</div>
				{this.state.open &&
					<>
					<div>Сама страница</div>
					</>
				}
			</>
		);
	}
}
PageSelect.propTypes = {
	options: PropTypes.array.isRequired,
	values: PropTypes.array.isRequired,
	onChange: PropTypes.func.isRequired
};

PageSelect.defaultProps = {
	options: [],
	values:[],
	onChange:(e)=>{
		return null;
	}
};
function WithNavigate(props) {
	return (
		<PageSelect navigate={useNavigate()} location={useLocation()} {...props} />
	);
}
export default withTranslation()(WithNavigate);
