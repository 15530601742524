import ls from 'local-storage'
import ExternalStorage from "./ExternalStorage";


const Storage = {
    set: function (name, value) {
        return ls.set(name, value)
    },
    get: function (name) {
        return ls.get(name);
    },
    remove: function (name) {
        ls.remove(name)
    },
    clear: function () {
        ls.clear()
    },
    ExternalStorage: ExternalStorage

}

export default Storage