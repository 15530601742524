import ls from 'local-storage'
import axios from 'axios'
import Cookies from 'js-cookie'
import i18n from './i18n';
import translationRU from '../locales/ru/translation.json';
import translationEN from '../locales/en/translation.json';
import Storage from "./Storage";
import Alert from "../components/helpers/Alert";
import store from "../store"
import {alertShow, alertHide} from "../reducers/Alert/init"

import {default as API2} from "./API";
import {favourites} from "../reducers/Favourites/Init";


const BaseAPI_URL = "http://45.86.183.21:8889";
const ResourceURL = "http://45.86.183.21:8888";
const SiteUrl = "https://fc.city/";

const Langs = [
	{name: "Русский", code: "ru"},
	{name: "English", code: "en"},
]
// the translations
const GetToken = function () {
	return Storage.get('token');
}
const API = axios.create({
	baseURL: BaseAPI_URL,
	timeout: 60000,
	headers: {
		Authorization: 'Bearer ' + GetToken() /* Указываем токен из кук */,
	},
	transformRequest: [function (data, headers) {
		// Do whatever you want to transform the data
		//@TODO regenerate token
		this.headers.Authorization = 'Bearer ' + GetToken()
		return data;
	}, ...axios.defaults.transformRequest],
	validateStatus: function (status) {

		if (status === 401) {
			//window.location.href = "/"
			Storage.remove('token')
			//window.location.reload();
			return false;
		}
		if (status > 405) {
			//ToastAlert('Unknown error')
		}
		return (
			status < 10000
		) /* Чтобы не писать полноценный обработчик кодов ответа - всегда передаем ответ */
	},
})

/* Просто хелпер как обертка для частых функций */
API.interceptors.request.use(function (config) {
	// Do something before request is sent
	//alert('BEFORE');
	return config;
}, function (error) {

	// Do something with request error
	return Promise.reject(error);
});

/*
Cookies const
firstLoad - first start app bool (false if first)
city_default = Default user city !required!
country_default = Default user country !required!
lang = User language
user_role = one of guest || user if false - login_Screen
*/

function updatefavourites(){

}

const Helper = {
	UpdateFavourites:function(){
		API.get('/profile/favourites').then((response)=>{
			if (response.data.status) {
				store.dispatch(favourites(response.data.data))
			}
		}).catch(function (error) {
		})
	},
	SiteUrl: SiteUrl,
	Storage: Storage,
	ResourceURL: ResourceURL,
	replaceURL: function(url){
		return ResourceURL+url;
	},
	ExternalStorage: Storage.ExternalStorage,
	API: API,
	store: store,
	Cookies: Storage,
	setlang: function (code) {
		i18n.changeLanguage(code)
		ls.set('lang', code)
		store.dispatch(alertHide())
	},
	axios: axios,
	t: function (t, options = {}) {
		return i18n.t(t, options)
	},
	langs: Langs,
	alertHide: function () {
		store.dispatch(alertHide())
	},
	Alert: function (text, title = false, btns = []) {
		title = (title) ? title : i18n.t('Ошибка!')
		store.dispatch(alertShow(text, title, btns))
	},
	ls: ls,
	GetData: function (e) {
		return ls.get("userdata") && ls.get("userdata")[e]
			? ls.get("userdata")[e]
			: false;
	},
	whenAvailable: function (name, callback) {
		var interval = 10; // ms
		window.setTimeout(function () {
			if (window[name]) {
				callback(window[name]);
			} else {
				this.whenAvailable(name, callback);
			}
		}, interval);
	}
}

export default Helper