import React, {Fragment} from "react";
import {Link, useLocation, useNavigate} from "react-router-dom";
import {withTranslation} from "react-i18next";
import {Col} from "react-bootstrap";
import './ShopMini.css'
import Favourites from "../../helpers/Favourites";
import ExternalPage from "../../Helper/ExternalPage";
import Helper from "../../../helpers/Helper";

class ShopMini extends React.Component {
	constructor(props) {
		super(props);
		this.CloseCashbackPage = this.CloseCashbackPage.bind(this);
	}

	state = {
		CashbackPage: "",
	};

	async componentDidMount() {
	}

	async CloseCashbackPage() {
		this.setState({CashbackPage: ''})
	}

	async OpenCashbackPage(data) {
		this.setState({CashbackPage: data})
	}

	render() {
		return (<>
			{this.state.CashbackPage}
			<Col xs={6} sm={6} md={2} lg={2} className={'mb-4'} onClick={(e) => {
				if (e.target.tagName !== 'path' && e.target.tagName !== 'svg' && !e.target.classList.contains('heart_fav')) {
					this.OpenCashbackPage(<ExternalPage data={"sss"} top={<Favourites right={'1rem'} top={'0.65rem'}
																					  type={"cashback"}
																					  id={this.props.data.id}/>}
														callback={this.CloseCashbackPage}/>)
				}

			}}>
				<div className={"shop_badge"}>
					<div className={"bages top-promo-action"}>
						<div className={"action fd"}>Акция</div>
						<div className={"promo fd"}>Промо</div>
						<div className={"top fd"}>Топ</div>
					</div>
					<Favourites type={"cashback"} id={this.props.data.id}/>
					<div className={"img_div"}><img className={"img"} src={this.props.data.image}/></div>
					<span className={"name"}>{this.props.data.name}</span>
					<div className={"dash"}></div>
					<span className={"text"}>Кэшбэк</span>
					<div className={"text-cashback"}>
						<div className={"cashback"}>до 70% + 10%FC</div>
					</div>
					<div className={"stars"}>
						<div className={"star"}>
							<svg width="11" height="10" viewBox="0 0 11 10" fill="none"
								 xmlns="http://www.w3.org/2000/svg">
								<path
									d="M5.30979 0.58541C5.36966 0.401148 5.63034 0.401148 5.69021 0.58541L6.68992 3.66221C6.7167 3.74461 6.79349 3.80041 6.88014 3.80041H10.1153C10.309 3.80041 10.3896 4.04833 10.2328 4.16221L7.61555 6.06378C7.54545 6.11471 7.51612 6.20498 7.54289 6.28738L8.54261 9.36418C8.60248 9.54845 8.39158 9.70167 8.23484 9.58779L5.61756 7.68622C5.54746 7.63529 5.45254 7.63529 5.38244 7.68622L2.76516 9.58779C2.60842 9.70167 2.39752 9.54845 2.45739 9.36418L3.45711 6.28738C3.48388 6.20498 3.45455 6.11471 3.38445 6.06378L0.767169 4.16221C0.610426 4.04833 0.690981 3.80041 0.884726 3.80041H4.11986C4.20651 3.80041 4.2833 3.74461 4.31008 3.66221L5.30979 0.58541Z"
									fill="#FDCC43"/>
							</svg>
						</div>
						<div className={"star"}>
							<svg width="11" height="10" viewBox="0 0 11 10" fill="none"
								 xmlns="http://www.w3.org/2000/svg">
								<path
									d="M5.30979 0.58541C5.36966 0.401148 5.63034 0.401148 5.69021 0.58541L6.68992 3.66221C6.7167 3.74461 6.79349 3.80041 6.88014 3.80041H10.1153C10.309 3.80041 10.3896 4.04833 10.2328 4.16221L7.61555 6.06378C7.54545 6.11471 7.51612 6.20498 7.54289 6.28738L8.54261 9.36418C8.60248 9.54845 8.39158 9.70167 8.23484 9.58779L5.61756 7.68622C5.54746 7.63529 5.45254 7.63529 5.38244 7.68622L2.76516 9.58779C2.60842 9.70167 2.39752 9.54845 2.45739 9.36418L3.45711 6.28738C3.48388 6.20498 3.45455 6.11471 3.38445 6.06378L0.767169 4.16221C0.610426 4.04833 0.690981 3.80041 0.884726 3.80041H4.11986C4.20651 3.80041 4.2833 3.74461 4.31008 3.66221L5.30979 0.58541Z"
									fill="#FDCC43"/>
							</svg>
						</div>
						<div className={"star"}>
							<svg width="11" height="10" viewBox="0 0 11 10" fill="none"
								 xmlns="http://www.w3.org/2000/svg">
								<path
									d="M5.30979 0.58541C5.36966 0.401148 5.63034 0.401148 5.69021 0.58541L6.68992 3.66221C6.7167 3.74461 6.79349 3.80041 6.88014 3.80041H10.1153C10.309 3.80041 10.3896 4.04833 10.2328 4.16221L7.61555 6.06378C7.54545 6.11471 7.51612 6.20498 7.54289 6.28738L8.54261 9.36418C8.60248 9.54845 8.39158 9.70167 8.23484 9.58779L5.61756 7.68622C5.54746 7.63529 5.45254 7.63529 5.38244 7.68622L2.76516 9.58779C2.60842 9.70167 2.39752 9.54845 2.45739 9.36418L3.45711 6.28738C3.48388 6.20498 3.45455 6.11471 3.38445 6.06378L0.767169 4.16221C0.610426 4.04833 0.690981 3.80041 0.884726 3.80041H4.11986C4.20651 3.80041 4.2833 3.74461 4.31008 3.66221L5.30979 0.58541Z"
									fill="#FDCC43"/>
							</svg>
						</div>
						<div className={"star"}>
							<svg width="11" height="10" viewBox="0 0 11 10" fill="none"
								 xmlns="http://www.w3.org/2000/svg">
								<path
									d="M5.30979 0.58541C5.36966 0.401148 5.63034 0.401148 5.69021 0.58541L6.68992 3.66221C6.7167 3.74461 6.79349 3.80041 6.88014 3.80041H10.1153C10.309 3.80041 10.3896 4.04833 10.2328 4.16221L7.61555 6.06378C7.54545 6.11471 7.51612 6.20498 7.54289 6.28738L8.54261 9.36418C8.60248 9.54845 8.39158 9.70167 8.23484 9.58779L5.61756 7.68622C5.54746 7.63529 5.45254 7.63529 5.38244 7.68622L2.76516 9.58779C2.60842 9.70167 2.39752 9.54845 2.45739 9.36418L3.45711 6.28738C3.48388 6.20498 3.45455 6.11471 3.38445 6.06378L0.767169 4.16221C0.610426 4.04833 0.690981 3.80041 0.884726 3.80041H4.11986C4.20651 3.80041 4.2833 3.74461 4.31008 3.66221L5.30979 0.58541Z"
									fill="#FDCC43"/>
							</svg>
						</div>
						<div className={"star"}>
							<svg width="11" height="10" viewBox="0 0 11 10" fill="none"
								 xmlns="http://www.w3.org/2000/svg">
								<path
									d="M5.30979 0.58541C5.36966 0.401148 5.63034 0.401148 5.69021 0.58541L6.68992 3.66221C6.7167 3.74461 6.79349 3.80041 6.88014 3.80041H10.1153C10.309 3.80041 10.3896 4.04833 10.2328 4.16221L7.61555 6.06378C7.54545 6.11471 7.51612 6.20498 7.54289 6.28738L8.54261 9.36418C8.60248 9.54845 8.39158 9.70167 8.23484 9.58779L5.61756 7.68622C5.54746 7.63529 5.45254 7.63529 5.38244 7.68622L2.76516 9.58779C2.60842 9.70167 2.39752 9.54845 2.45739 9.36418L3.45711 6.28738C3.48388 6.20498 3.45455 6.11471 3.38445 6.06378L0.767169 4.16221C0.610426 4.04833 0.690981 3.80041 0.884726 3.80041H4.11986C4.20651 3.80041 4.2833 3.74461 4.31008 3.66221L5.30979 0.58541Z"
									fill="#FDCC43"/>
							</svg>
						</div>
						<div className={"star-text"}>
							5
						</div>
					</div>
				</div>
			</Col>
		</>);
	}
}

function WithNavigate(props) {
	return (<ShopMini navigate={useNavigate()} location={useLocation()} {...props} />);
}

export default withTranslation()(WithNavigate);
