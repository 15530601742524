import React, { Fragment } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {withTranslation} from "react-i18next";

import {Col, Row, Image, Button, Badge, Form} from "react-bootstrap";
import Helper from "../../../helpers/Helper";
import "./Profile.css"
import iconCoins from "../../../files/img/profile/coins.svg"
import iconUser from "../../../files/img/profile/profile/user.svg"
import iconBirthday from "../../../files/img/profile/profile/birthday.svg"
import iconSex from "../../../files/img/profile/profile/sex.svg"
import iconPhone from "../../../files/img/profile/profile/phone.svg"
import iconMail from "../../../files/img/profile/profile/mail.svg"
import iconSendmail from "../../../files/img/profile/profile/sendmail.svg"
class Profile extends React.Component {
	constructor(props) {
		super(props);
	}
	state = {
		data: {
			avatar: "",
			email: "",
			kulname: "",
			status_names: ["", "", ""],
			country:{
				icon:"",
				currency:{
					name:""
				}
			},
			balances: {
				balance: 0,
				balanceBONUS: 0,
				balanceCB: 0,
				balanceCBF: 0,
				balanceFC: 0,
				balanceFull: 0,
				balanceNoFull: 0,
				balanceREF: 0,
				checkCashback: 0,
				marketingTotal: 0,
				payments: 0
			}
		},
		editor:{
			kulname: "",
			birthday: "",
			sex: 0,
			phone: '',
			email: '',
		}
	};
	async getProfile(){
		Helper.API.get('/profile').then((response) => {
			if (response.data.status) {
				console.log(response.data.data)
				this.setState({data: response.data.data}).then((e)=>{
				
				})
				
			}
		}).catch(function (error) {
		
		})
	}
	async componentDidMount() {
		this.getProfile()
	}
	render() {
		return (
			<>
				<Col xs={12} sm={12} md={12} lg={12} className={'mb-4 p-2 profile-page'}>
					<div className={"profile-top"}>
						<div className={"left pe-2"}><Image className={"avatar"} style={{height: "5rem"}}
															srcSet={Helper.ResourceURL + this.state.data.avatar}
															roundedCircle/></div>
						<div className={"right ps-2"}>
							<div className={"name"}>{this.state.data.kulname}</div>
							<div className={"mail"}>{this.state.data.email}</div>
							<div className={"balance"}><img srcSet={iconCoins}/></div>
						</div>
					</div>
					
					<Row className={"editor pt-2"}>
						<Col xs={5} sm={5} md={5} lg={5} className={'text'}>
							<img srcSet={iconUser} className={"pe-2"}/>Имя
						</Col>
						<Col xs={7} sm={7} md={7} lg={7} className={''}>
							<Form.Control type="text" placeholder="" value={this.state.editor.kulname !='' ? this.state.editor.kulname : this.state.data.kulname} onChange={(e)=>{
								var data = this.state.editor
								data.kulname = e.target.value
								this.setState({editor:data})
								console.log(this.state)
							}} />
						</Col>
					</Row>
					
					<Row className={"editor pt-2"}>
						<Col xs={5} sm={5} md={5} lg={5} className={'text'}>
							Страна и валюта
						</Col>
						<Col xs={7} sm={7} md={7} lg={7} className={''}>
							{this.state.data.country_id && this.state.data.country_id == 0 &&
								<></>
								
							}
							{this.state.data.country_id && this.state.data.country_id != 0 &&
								<>
									<img style={{width:"3rem"}} srcSet={Helper.ResourceURL+this.state.data.country.icon}/>{this.state.data.country.name},{this.state.data.country.currency.name}
								</>
								
							}
						</Col>
					</Row>
					
					<Row className={"editor pt-2"}>
						<Col xs={5} sm={5} md={5} lg={5} className={'text'}>
							<img srcSet={iconBirthday} className={"pe-2"}/>День рождения
						</Col>
						<Col xs={7} sm={7} md={7} lg={7} className={''}>
							<Form.Control type="date" placeholder="" value={this.state.editor.birthday !='' ? this.state.editor.birthday : this.state.data.birthday} onChange={(e)=>{
								var data = this.state.editor
								data.birthday = e.target.value
								this.setState({editor:data})
								console.log(this.state)
							}} />
						</Col>
					</Row>
					
					<Row className={"editor pt-2"}>
						<Col xs={5} sm={5} md={5} lg={5} className={'text'}>
							<img srcSet={iconSex} className={"pe-2"}/>Пол
						</Col>
						<Col xs={7} sm={7} md={7} lg={7} className={''}>
							<Form.Select aria-label="Default select example">
								<option value="1" selected={(this.state.editor.sex == 0) ? ((this.state.data.sex == 1) ? true : false) : ((this.state.editor.sex==1) ? true : false)}>Мужской</option>
								<option value="2" selected={(this.state.editor.sex == 0) ? ((this.state.data.sex == 2) ? true : false) : ((this.state.editor.sex==2) ? true : false)}>Женский</option>
							</Form.Select>
						</Col>
					</Row>
					
					<Row className={"editor pt-2"}>
						<Col xs={5} sm={5} md={5} lg={5} className={'text'}>
							<img srcSet={iconPhone} className={"pe-2"}/>Телефон
						</Col>
						<Col xs={7} sm={7} md={7} lg={7} className={''}>
							<Form.Control type="tel" placeholder="" value={this.state.editor.phone !='' ? this.state.editor.phone : this.state.data.phone} onChange={(e)=>{
								var data = this.state.editor
								data.phone = e.target.value
								this.setState({editor:data})
							}} />
						</Col>
					</Row>
					
					<Row className={"editor pt-2"}>
						<Col xs={5} sm={5} md={5} lg={5} className={'text'}>
							<img srcSet={iconMail} className={"pe-2"}/>Эл.почта
						</Col>
						<Col xs={7} sm={7} md={7} lg={7} className={''}>
							<Form.Control type="tel" placeholder="" value={this.state.editor.email !='' ? this.state.editor.email : this.state.data.email} onChange={(e)=>{
								var data = this.state.editor
								data.email = e.target.value
								this.setState({editor:data})
							}} />
						</Col>
					</Row>
					
					<Row className={"editor pt-2"}>
						<Col xs={5} sm={5} md={5} lg={5} className={'text'}>
							<img srcSet={iconMail} className={"pe-2"}/>Рассылка
						</Col>
						<Col xs={7} sm={7} md={7} lg={7} className={''}>
							<Form.Check // prettier-ignore
								type="switch"
								id="custom-switch"
								size
							/>
						</Col>
					</Row>
				</Col>
			</>
		);
	}
}
function WithNavigate(props) {
	return (
		<Profile navigate={useNavigate()} location={useLocation()} {...props} />
	);
}
export default withTranslation()(WithNavigate);
