import React, {Fragment} from "react";
import {Link, useLocation, useNavigate} from "react-router-dom";
import {withTranslation} from "react-i18next";
import './UserCashback.css'
import Helper from "../../../helpers/Helper";
import UserCashbackOnlineOrderMini from "./Online/UserCashbackOnlineOrderMini";
import Container from "react-bootstrap/Container";
import {Col, Row} from "react-bootstrap";

class UserCashback extends React.Component {
	constructor(props) {
		super(props);
	}

	state = {
		payments: [],
		classTab1:"",
		classTab2:"",
	};

	async componentDidMount() {
		var payments = await Helper.API.get("/profile/cashback/payments")
		if (payments.data.status) {
			this.setState({payments: payments.data.data})
		}

	}

	render() {
		return (
			<>
				<Container className={"page-UserCashbackOnline"}>
					<Row>
						<Col xs={12} sm={12} md={12} lg={12} className={"mb-2 toplink"}>
							<div className={"me-2 "+((this.state.classTab1 == "hide") ? '' : 'active')} onClick={(e)=>{
								this.setState({classTab1:"active show"})
								this.setState({classTab2:"hide"})
							}}>Кэшбэк онлайн</div>
							<div className={"ms-2 "+((this.state.classTab1 == "hide") ? 'active' : '')} onClick={(e)=>{
								this.setState({classTab1:"hide"})
								this.setState({classTab2:"active show"})
							}}>Кэшбэк по чеку</div>
						</Col>
					</Row>
					<div className={"tabs"} style={{position:"relative"}}>
					<Row className={"tab-1 active "+this.state.classTab1}>
						{this.state.payments.map((pay) => {
							return (
								<UserCashbackOnlineOrderMini data={pay}/>
							)
						})}
					</Row>
					<Row className={"tab-2 "+this.state.classTab2}>
						111
					</Row></div>
				</Container>
			</>
		);
	}
}

function WithNavigate(props) {
	return (
		<UserCashback navigate={useNavigate()} location={useLocation()} {...props} />
	);
}

export default withTranslation()(WithNavigate);
