import React, {Fragment} from "react";
import {Link, useLocation, useNavigate} from "react-router-dom";
import {withTranslation} from "react-i18next";
import Helper from "../../helpers/Helper";
import Footer from "../../components/design/Footer";
import {Container, Col, Row} from "react-bootstrap";
import "./Cards.css"
import Topmenu from "../../components/design/Topmenu/Topmenu";
import Card from "./Card";
import ExternalPage from "../../components/Helper/ExternalPage";
import Good from "../goods/Good";
import Favourites from "../../components/helpers/Favourites";

class Index extends React.Component {
	constructor(props) {
		super(props);

		this.CloseCardPage = this.CloseCardPage.bind(this);
	}

	state = {
		USER_CARDS: [],
		CARDS_XF: [],
		card_sort: "default",
		card_select:0,
		card_last_select:0,
		CardPage:""
	};


	async GetCards() {
		var CARDS = await Helper.ExternalStorage.get("USER_CARDS");
		if (CARDS) {
			var CARDS_XF = await Helper.ExternalStorage.get("CARDS_XF");
			var CARDS_XF = JSON.parse(CARDS_XF);
			this.setState({CARDS_XF: CARDS_XF})
			this.setState({USER_CARDS: JSON.parse(CARDS)})
			return false;
		}
		var CARDS_XF = await Helper.API.get('/cards/xf');
		if (CARDS_XF && CARDS_XF.data && CARDS_XF.data.status) {
			CARDS_XF = CARDS_XF.data.data
		} else {
			CARDS_XF = [];
		}
		if(Helper.Cookies.get('user_role') == "guest"){
			var USER_CARDS = []
		}else {
			var USER_CARDS = await Helper.API.get('/cards')
			if (USER_CARDS && USER_CARDS.data && USER_CARDS.data.status) {
				USER_CARDS = USER_CARDS.data.data
				Helper.ExternalStorage.set('USER_CARDS', JSON.stringify(USER_CARDS))
				Helper.ExternalStorage.set('CARDS_XF', JSON.stringify(CARDS_XF))
			} else {
				USER_CARDS = [];
			}
		}
		this.setState({CARDS_XF: CARDS_XF})
		this.setState({USER_CARDS: USER_CARDS})
		/*
				USER_CARDS.data.data.map((e)=>{
					console.log(e);
					if (value.shop_id > 0) {

					}else{

					}
				})

		 */
		/*
		Helper.API.get('/cards/xf').then((response)=>{
			if(response && response.data && response.data.status){
				console.log(response.data.data);
				Helper.ExternalStorage.set('CARDS_XF',response.data.data)
				Helper.API.get('/cards').then((res)=>{
					if(res && res.data && res.data.status){
						Helper.ExternalStorage.set('CARDS_XF',response.data.data)
					}
				},(err)=>{

				})
			}
		},(error)=>{

		});*/
		//Проверяем кеш - выводим
		//Проверяем интернет
		//Если интернет есть - получаем карты и обновляем кеш и состояние
	}

	async componentDidMount() {
		document.getElementById('root').scrollTop = 0;
		console.log("WTF")
		this.GetCards()
	}
	SelectCard(id){
		var old = this.state.card_select
		this.setState({card_select:id})
		if(id == old){
			console.log("/"+this.props.role+'/card/'+id)

			this.OpenCardPage(<ExternalPage data={ <Card role={'user'} id={id}/> } callback={this.CloseCardPage} />)

			this.setState({card_last_select:0})
		}else{
			this.setState({card_last_select:old})
		}

	}

	async CloseCardPage() {
		this.setState({CardPage: ''})
	}

	async OpenCardPage(data) {
		this.setState({CardPage: data})
	}
	render() {
		return (
			<>

				<Topmenu/>
				<Container>
					{this.state.CardPage}
					<Row className={"ps-4 pe-4"}>
						<Col md={12}>
							qwdqwd
						</Col>
					</Row>
					<Row className={"ps-4 pe-4"} style={{paddingBottom:"5rem",paddingTop:"14rem"}}>
						<Col md={12}>
							<div className={"cards-s"}>
								{this.state.USER_CARDS.map((value) => {
									if (value.shop_id > 0 && this.state.CARDS_XF.front[value.shop_id]) {
										if (this.state.CARDS_XF.front[value.shop_id] !== false && this.state.card_sort !== 'mini') {
											return (
												<div key={value.id} data-id={value.id} className={((this.state.card_last_select == value.id) ? 'hideCard ' : '')+((this.state.card_select == value.id) ? 'showCard ' : '')+"card-s-item"}
													 onClick={(e)=>{
														 this.SelectCard(value.id)
													 }}
												>
													<img srcSet={Helper.ResourceURL+this.state.CARDS_XF.front[value.shop_id]}/>
												</div>
											)
										} else if ((this.state.CARDS_XF.front[value.shop_id] === false || this.state.card_sort === 'mini') && this.state.CARDS_XF.icon[value.shop_id] !== '') {

											return (

												<div key={value.id} style={{backgroundColor: value.color}} data-id={value.id}
													 onClick={(e)=>{
														 this.SelectCard(value.id)
													 }}
													 className={((this.state.card_last_select == value.id) ? 'hideCard ' : '')+((this.state.card_select == value.id) ? 'showCard ' : '')+"card-s-item user"}>
													<img className={"letter"} srcSet={Helper.ResourceURL+value.icon}/>
												</div>
											)
										} else if ((this.state.CARDS_XF.front[value.shop_id] === false || this.state.card_sort === 'mini') && this.state.CARDS_XF.icon[value.shop_id] === false) {

											return (
												<div key={value.id} style={{backgroundColor: value.color}} data-id={value.id}
													 onClick={(e)=>{
														 this.SelectCard(value.id)
													 }}
													 className={((this.state.card_last_select == value.id) ? 'hideCard ' : '')+((this.state.card_select == value.id) ? 'showCard ' : '')+"card-s-item user"}>
													<div
														className="letter">{value.shop_name.substring(0, 1).toUpperCase()}</div>
												</div>
											)
										}
									} else {
										return (
											<div key={value.id} style={{backgroundColor: value.color}} data-id={value.id}
												 onClick={(e)=>{
													 this.SelectCard(value.id)
												 }}
												 className={((this.state.card_last_select == value.id) ? 'hideCard ' : '')+((this.state.card_select == value.id) ? 'showCard ' : '')+"card-s-item user"}>
												<div
													className={"letter"}>{value.shop_name.substring(0, 1).toUpperCase()}</div>
												<div className={"letter2"}>{value.shop_name}</div>
											</div>
										)
									}
								})}
							</div>
						</Col>
					</Row>
				</Container>
				<Footer card/>
			</>
		);
	}
}

function WithNavigate(props) {
	return (
		<Index navigate={useNavigate()} location={useLocation()} {...props} />
	);
}

export default withTranslation()(WithNavigate);
