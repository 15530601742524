import {getBase64Image} from "../Images";
import ls from 'local-storage'

var ExternalStorage = {}
ExternalStorage.set = async function (key, value) {
    var ret = ls.set(key, value);
    return ret
}
ExternalStorage.get = async function (key) {
    return ls.get(key)

}
ExternalStorage.delete= async function (key) {
    return ls.remove(key)
}
ExternalStorage.files= async function () {
    return []
}

ExternalStorage.file_exist= async function (key) {
    return !!(await ExternalStorage.get(key))
}

ExternalStorage.saveImage= async function (url, name,baseurl=false,renew=false) {

    baseurl = (baseurl) ? baseurl : "";
    url = baseurl+url
    return url;

    //return await window.STORAGE.read(key)
}
ExternalStorage.GEO = function(Condefault=[55.7,37.6]) {
    return new Promise((resolve, reject) => {
        if (!navigator.geolocation) {
            console.log("resolve4")
            console.log(Condefault)
            resolve(Condefault)
        } else {
            navigator.geolocation.getCurrentPosition(function (success) {
                if(typeof(success) == "undefined"){
                    console.log("resolve3")
                    console.log(Condefault)
                    resolve(Condefault)
                }else{
                    console.log("resolve1")
                    resolve([success.coords.latitude, success.coords.longitude])
                }
            }, function (error) {
                console.log("resolve2")
                console.log(Condefault)
                resolve(Condefault)
            });
        }
    })

}
export default ExternalStorage