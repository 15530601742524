import React, {Fragment} from "react";
import {Link, useLocation, useNavigate} from "react-router-dom";
import {withTranslation} from "react-i18next";
import {Button, Card, Col} from "react-bootstrap";
import './GoodMini.css'
import Favourites from "../../helpers/Favourites";
import Good from "../../../pages/goods/Good";
import Helper from "../../../helpers/Helper";
import ExternalPage from "../../Helper/ExternalPage";

class GoodMini extends React.Component {
	constructor(props) {
		super(props);
		this.CloseGoodPage = this.CloseGoodPage.bind(this);
	}

	state = {
		GoodPage:""
	};

	async componentDidMount() {

	}

	async CloseGoodPage(){
		this.setState({GoodPage:''})
	}
	async OpenGoodPage(data){
		this.setState({GoodPage:data})
	}
	render() {
		return (<>
			{this.state.GoodPage}
			<Col xs={6} sm={6} md={3} lg={2} className={'mb-4 GoodMini'} id={"GoodMini_"+this.props.data.goods_id} onClick={(e)=>{
				if(e.target.tagName !== 'path' && e.target.tagName !== 'svg' && !e.target.classList.contains('heart_fav')) {
					console.log('OpenGoodPage')
					this.OpenGoodPage(<ExternalPage data={ <Good id={this.props.data.goods_id}/> } top={<Favourites right={'1rem'} top={'0.65rem'} type={"goods"} id={this.props.data.goods_id}/>} callback={this.CloseGoodPage} />)
				}

			}}>
				<Card>
					<Favourites type={"goods"} id={this.props.data.goods_id}/>
					<div className={"imgplace"}>
						<Card.Img variant="top" src={this.props.data.image} />
					</div>

					<Card.Title>Кэшбек<br/>до 8.5% + 10% FC</Card.Title>
					<Card.Body className={"p-0"}>
						<Card.Text className={"m-0 pt-2"}>
							<div className={"price"}>{this.props.data.price} {Helper.Cookies.get('country_data').currency.sign}</div>
						</Card.Text>
						<div className={"name"}>{this.props.data.name.substr(0, 50)}</div>
						<div className={"p-2"}><Button style={{width:'100%'}} variant="light">{this.props.data.store}</Button></div>

					</Card.Body>
				</Card>
			</Col>
		</>);
	}
}

function WithNavigate(props) {
	return (<GoodMini navigate={useNavigate()} location={useLocation()} {...props} />);
}

export default withTranslation()(WithNavigate);
