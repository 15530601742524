import React, {Fragment} from "react";
import {Link, useLocation, useNavigate} from "react-router-dom";
import {withTranslation} from "react-i18next";
import Header from "../../components/design/Header";
import Container from "react-bootstrap/Container";
import {Button, Carousel, Col, Placeholder, Row} from "react-bootstrap";
import GoodMini from "../../components/catalog/goods/GoodMini";
import {faArrowLeft, faChevronLeft} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Favourites from "../../components/helpers/Favourites";
import Helper from "../../helpers/Helper";
import "./ExternalPage.css"
class ExternalPage extends React.Component {
	constructor(props) {
		super(props);
	}

	state = {
		Open: true,
		data: "",
		top: "",
		title:""
	};


	async componentDidMount() {
		console.log("this.props")
		console.log(this.props)
		console.log("this.props")
		if (this.props.data) {
			this.setState({data: this.props.data})
		}
		if (this.props.top) {
			this.setState({top: this.props.top})
		}
		if (this.props.title) {
			this.setState({title: this.props.title})
		}
	}

	async delay(ms = 500) {
		return new Promise(
			resolve => setTimeout(resolve, ms)
		)
	}

	render() {
		return (
			<>
				<div className={"ExternalPage GoodPage " + ((this.state.Open) ? "GoodPageOpen" : 'GoodPageClose')}>
					<div className={"back"}>
						<FontAwesomeIcon icon={faChevronLeft} onClick={() => {

							this.setState({Open: false})
							var self = this
							this.delay().then((e) => {
								if (self.props.callback) {
									self.props.callback()
								}
							})


						}}/>
						{this.state.top}
						{this.state.title !== '' &&
							<div className={"title"}>{this.state.title}</div>
						}
					</div>
					<Container className={" "+  (this.props.className) ? this.props.className : ""}>
						{this.state.data}
					</Container>
				</div>
			</>
		);
	}
}

function WithNavigate(props) {
	return (
		<ExternalPage navigate={useNavigate()} location={useLocation()} {...props} />
	);
}

export default withTranslation()(WithNavigate);
