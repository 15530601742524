import React, {Fragment} from "react";
import {Link, useLocation, useNavigate} from "react-router-dom";
import {withTranslation} from "react-i18next";
import Helper from "../../helpers/Helper";
import Footer from "../../components/design/Footer";
import './Getcashback.css'
import Topmenu from "../../components/design/Topmenu/Topmenu";
import Container from "react-bootstrap/Container";
import {Button, Col, Row, Spinner} from "react-bootstrap";
import ShopMini from "../../components/catalog/getcashback/ShopMini";

class Getcashback extends React.Component {
	constructor(props) {
		super(props);
		document.getElementById('root').scrollTop = 0;
	}
	state = {
		Goods:{
			items:[],
			page:0,
			pages:0
		},
		BtnLoading:false
	};
	async LoadNextPage(){
		Helper.API.get('/catalog/goods/cashbackShops?page='+(Number(this.state.Goods.page)+1)).then((response)=>{
			if (response.data.status) {
				if(response.data.data.page > this.state.Goods.page) {
					var Goods = this.state.Goods;
					Goods.items = [].concat(Goods.items, response.data.data.data);
					Goods.page = response.data.data.page
					Goods.pages = response.data.data.pages
					this.setState({Goods: Goods})
				}
			}
			this.setState({BtnLoading:false})
		}).catch(function (error) {
			this.setState({BtnLoading:false})
		})
	}
	async componentDidMount() {
			this.LoadNextPage()
	}
	render() {
		return (
			<>

				<Topmenu/>
				<Container>
					<Row className={"mt-3 pb-5"}>

						{this.state.Goods.items.map((item) => {
							return(
								<ShopMini data={item}/>
							)

						})}
						{
							this.state.Goods.page < this.state.Goods.pages
								? <Col><Button variant="warning" disabled={this.state.BtnLoading} onClick={(e)=>{
									this.setState({BtnLoading:true})
									this.LoadNextPage()
								}} className={"mb-5"} style={{width:'100%'}}>
									{this.state.BtnLoading ? <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" /> : ''}
									{this.state.BtnLoading ? ' '+Helper.t("Loading")+'...' : Helper.t("LoadingMore")}
								</Button></Col>
								: ''
						}
					</Row>
				</Container>
				<Footer search/>
			</>
		);
	}
}
function WithNavigate(props) {
	return (
		<Getcashback navigate={useNavigate()} location={useLocation()} {...props} />
	);
}
export default withTranslation()(WithNavigate);
