import React, { Fragment } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {withTranslation} from "react-i18next";
import Helper from "../../../helpers/Helper";
import MarketingMini from "./MarketingMini";
import Container from "react-bootstrap/Container";
import {Button, ButtonGroup, Row} from "react-bootstrap";
import MarketingUserMini from "./MarketingUserMini";

class UserMarketing extends React.Component {
	constructor(props) {
		super(props);
		this.GetAllData = this.GetAllData.bind(this);
	}
	state = {
		jobs:[],
		UserJobs:[],
		activeBtn:0,
		rules:""
	};
async GetUserJobs() {
///profile/marketing/tasks
	Helper.API.get('/profile/marketing/tasks').then((response) => {
		if (response.data.status) {

			this.setState({UserJobs: response.data.data})

		}
	}).catch(function (error) {

	})
}
async GetRules(){
	Helper.API.get('/profile/marketing/rules').then((response) => {
		if (response.data.status) {

			this.setState({rules: response.data.data})
			console.log(response.data.data);
			//alert(this.state.rules);
		}
	}).catch(function (error) {

	})
}
async GetAllData(){
	
	var data = await Helper.API.get('/profile/marketing')
	if(data.data && data.data.status){
		this.setState({jobs:data.data.data})
	}
	this.GetRules();
	this.GetUserJobs();
}
	async componentDidMount() {
	this.GetAllData()
	}
	render() {
		return (
			<>
				<Container className={"page-profileMarketing"}>
					<Row className={"mt-3 pb-2"}>
						<ButtonGroup size="sm" aria-label="Basic example">
							<Button variant={((this.state.activeBtn==0) ? "warning" : "outline-light")} onClick={(e)=>{ this.setState({activeBtn:0}) }}>Все задания</Button>
							<Button variant={((this.state.activeBtn==1) ? "warning" : "outline-light")} onClick={(e)=>{ this.setState({activeBtn:1}) }}>Правила</Button>
							<Button variant={((this.state.activeBtn==2) ? "warning" : "outline-light")} onClick={(e)=>{ this.setState({activeBtn:2}) }}>Отчеты по заданию</Button>
						</ButtonGroup>
					</Row>
					<Row className={"mt-3 pb-2 "+((this.state.activeBtn==0) ? '' : 'd-none')}>
				{this.state.jobs.map((e)=>{
					return(
						<MarketingMini callback_update={this.GetAllData} data={e}/>
					)
				})}
					</Row>
					<Row className={"mt-3 pb-2 "+((this.state.activeBtn==1) ? '' : 'd-none')}>
						<div dangerouslySetInnerHTML={{ __html : this.state.rules }}/>
					</Row>
					<Row className={"mt-3 pb-2 "+((this.state.activeBtn==2) ? '' : 'd-none')}>
						{this.state.UserJobs.map((e)=>{
							if(e.job !== null){
								return(
									<MarketingUserMini data={e}/>
								)
							}
						})}
					</Row>
				</Container>
			</>
		);
	}
}
function WithNavigate(props) {
	return (
		<UserMarketing navigate={useNavigate()} location={useLocation()} {...props} />
	);
}
export default withTranslation()(WithNavigate);
