import React, { Fragment } from 'react'
import {Routes, Route, Navigate, useLocation, useNavigate} from 'react-router-dom'

import Helper from "./Helper";
import { isExpired, decodeToken } from "react-jwt";
function RequireAuth({ children }: { children: JSX.Element }) {
	console.log('RequireAuth');
	let token = Helper.Storage.get('token')
	let navigate = useNavigate();
if(!Helper.Storage.get('firstLoad')){
//window.location.href = '/first'
	console.log('first');
	return <Navigate to="/first"/>;
}
	if(!Helper.Storage.get('user_role')) {
		console.log('/auth/select');
		return <Navigate to="/auth/select" />
	}
	if(Helper.Storage.get('user_role') == 'guest') {
		console.log('/guest');
		return <Navigate to="/guest" />
	}
	if(Helper.Storage.get('user_role') == 'user') {
		console.log('/user');
		return <Navigate to="/user" />
	}
	console.log(token);
	var Expired = isExpired(token);
	var decodedToken = decodeToken(token);
	if (token == '' || !token || decodedToken == null || Expired == true) {
		// Redirect them to the /login page, but save the current location they were
		// trying to go to when they were redirected. This allows us to send them
		// along to that page after they login, which is a nicer user experience
		// than dropping them off on the home page.
		return <Navigate to="/auth/select" />
	}

	return children
}

export default RequireAuth
