import React, {Fragment} from "react";
import {Link, useLocation, useNavigate, useParams} from "react-router-dom";
import {withTranslation} from "react-i18next";
import Header, {HeaderBack} from "../../components/design/Header";
import Helper from "../../helpers/Helper";
import {Container, Col, Row, Navbar} from "react-bootstrap";
import "./Card.css"
import Barcode from "react-barcode";
import JsBarcode from "jsbarcode";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowLeft} from "@fortawesome/free-solid-svg-icons";
class Card extends React.Component {
	constructor(props) {
		super(props);
	}

	state = {
		id: this.props.id,
		CARD: {},
		barcode:"",
		card_code:""
	};

	async componentDidUpdate() {
		this.GetBarCode(this.state.CARD.code_type)
	}
	async componentDidMount() {
		//document.getElementById('root').scrollTop = 0;
		var USER_CARDS = await Helper.ExternalStorage.get("USER_CARDS");
		var CARDS_XF = await Helper.ExternalStorage.get("CARDS_XF");
		USER_CARDS = JSON.parse(USER_CARDS)
		var USER_CARDS_NUM = [];
		USER_CARDS.map(async (e) => {
			if (e.id == this.props.id) {
				e.barcode = (e.barcode !== '') ? e.barcode : e.card_number;
				this.setState({barcode:e.barcode})
				this.setState({CARD: e})
				window.CARD = e
				this.GetBarCode(e.code_type)
				var code = (e.barcode !== e.card_number) ? e.card_number : ''
				this.setState({card_code:code})
			}
		})


		//console.log(this.props.params.id)
		console.log(this.state.CARD)
	}

	GetBarCode(type) {
if(this.state.barcode !== '') {
	try {
		JsBarcode("#barcode", this.state.barcode, {
			format: this.GetBarCodesTypeJs(type)
		});
	} catch (e) {
		JsBarcode("#barcode", this.state.barcode, {
			format: 'CODE128'
		});

	}
}
	}
	GetBarCodesTypeJs(type) {
		switch (type) {
			case "EAN_8":
			case "EAN8":
				return "EAN8";
				break;
			case "EAN_13":
			case "EAN13":
				return "EAN13";
				break;
			case "CODE_128":
			case "CODE128":
				return "CODE128";
				break;
			case "CODE_39":
			case "CODE39":
				return "CODE39";
				break;
			case "ITF":
				return ('ITF');
				break;
			default:
				return "CODE128";
		}
	}

	CardTitle() {
		return (
			<>
				{Helper.t('Card.Карта')}&nbsp;< label> {this.state.CARD.shop_name} < /label>
			</>
		)
	}

	render() {


		return (
			<>


				<Container>
					<Row className={"ps-4 pe-4 pt-4"}>
						<Col md={12}>
							<div className={"render"} style={{backgroundColor: this.state.CARD.color}}>
								<div className={"logo"}>
									{this.state.CARD.icon
										?<img srcSet={Helper.replaceURL(this.state.CARD.icon)}/>
										:<><div className="letter">{this.state.CARD.shop_name ? this.state.CARD.shop_name.substring(0, 1).toUpperCase() : ''}</div><div class="letter2">{this.state.CARD.shop_name ? this.state.CARD.shop_name : ''}</div></>
									}

								</div>
								<div className={"barcode"}>

									<svg id={"barcode"}></svg>
									<div className={"code"}> {this.state.card_code} </div>
								</div>
							</div>
						</Col>
					</Row>
				</Container>

			</>
		);
	}
}

function WithNavigate(props) {
	return (
		<Card navigate={useNavigate()} location={useLocation()} params={useParams()} {...props} />
	);
}

export default withTranslation()(WithNavigate);
