import React, { Fragment } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {withTranslation} from "react-i18next";
import Container from "react-bootstrap/Container";
import Footer from "../../components/design/Footer";
class Osago extends React.Component {
	constructor(props) {
		super(props);
	}
	state = {
	
	};
	
	async componentDidMount() {
	
	}
	render() {
		return (
			<>
				<Container className={"page-osago"}>
					<iframe id="ntwsFrame" src="/ntws.html" name="ntws" scrolling="no" style={{width:"100%",height:"calc(100vh + 5rem)"}}></iframe>
				</Container>
				<Footer />
			</>
		);
	}
}
function WithNavigate(props) {
	return (
		<Osago navigate={useNavigate()} location={useLocation()} {...props} />
	);
}
export default withTranslation()(WithNavigate);
