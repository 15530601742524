import React from "react";
import {Navbar} from "react-bootstrap";
import Container from "react-bootstrap/Container";
import {useNavigate} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowLeft} from "@fortawesome/free-solid-svg-icons";


export function Header(data) {
	return (
		<Navbar style={data.style ? data.style : {}} expand="lg" className="bg-header">
			<Container className={'bg-header-container'}>
				{data.back ? HeaderBack() : ''}{data.text ? data.text : ''}
			</Container>
		</Navbar>
	)
}
export function HeaderBack(data) {
	let history = useNavigate();
	return (
		<div
			style={{left:'1rem',position:'absolute'}}
		>
			<FontAwesomeIcon icon={faArrowLeft} onClick={() => history(-1)} />
		</div>
	)
}
export default Header;