import React, {Fragment, useEffect, useState} from "react";
import Header from "../../components/design/Header";
import Helper from "../../helpers/Helper";
import {withTranslation} from 'react-i18next';
import WrldImg from '../../files/img/icons/world_globe.png';
import Select from 'react-select'
import {Container, Navbar, Col, Row, Form, Button} from "react-bootstrap";

import {useNavigate} from "react-router-dom";
function Country() {
	const [current, setCurrent] = useState([]);
	const [countrys, setCountrys] = useState([]);
	const [citiesbyId, setCitiesbyId] = useState([]);
	const [countrysbyId, setCountrysbyId] = useState([]);
	const [currentCountry, setCurrentCountry] = useState({});

	const [cities, setCities] = useState([]);
	const [currentCity, setCurrentCity] = useState({});
	const [isLoadingCities, setLoadingCities] = useState(0);
	const navigate = useNavigate();
	useEffect(() => {

		Helper.API.get('/assistant/cities').then((response) => {
			if (response.data.status) {
				var Countrys = []
				var CitiesbyId = [];
				response.data.data.map((Country) => {
					CitiesbyId[Country.id] = Country;
					Countrys.push({
						value: Country.id,
						label: Country.name + ((Country.currency_id > 0) ? ', ' + Country.currency.name : '')
					})
				})
				setCountrys(Countrys)
				setCitiesbyId(CitiesbyId)
			}
		}).catch(function (error) {

		})

		Helper.API.get('/assistant/countrys').then((response) => {
			if (response.data.status) {
				var Countrys = []
				var Countrysbyid = {}
				response.data.data.map((Country) => {
					Countrysbyid[Country.id] = Country;
					//Helper.ExternalStorage.saveImage(Helper.ResourceURL+Country.icon,'123');
					Countrys.push({
						value: Country.id,
						label: Country.name + ((Country.currency_id > 0) ? ', ' + Country.currency.name : '')
					})
				})
				setCountrys(Countrys)
				setCountrysbyId(Countrysbyid)
			}
		}).catch(function (error) {

		})

		Helper.API.get('/assistant/current').then((response) => {
			if (response.data.status) {
				setCurrent(response.data.data);
				setCurrentCountry({
					value: response.data.data.country.id,
					label: response.data.data.country.name + ((response.data.data.country.currency_id > 0) ? ', ' + response.data.data.country.currency.name : '')
				});
				setCurrentCity({
					value: response.data.data.city.id,
					label: response.data.data.city.name,
					country: response.data.data.city.countryid
				});

			}
		}).catch(function (error) {

		})
	}, [null]);

	useEffect(() => {
		if (currentCountry.value != currentCity.country) {
			setLoadingCities(1)
			setCurrentCity({});
			setCities([]);
		}
		Helper.API.get('/assistant/cities/' + currentCountry.value).then((response) => {
			if (response.data.status) {
				setLoadingCities(0)
				var Cities = []
				var CitiesbyId = [];
				if (currentCountry.value != currentCity.country) {
					setCurrentCity({
						value: response.data.data[0].id,
						label: response.data.data[0].name,
						country: response.data.data[0].countryid
					});
				}

				response.data.data.map((City) => {
					CitiesbyId[City.id] = City;
					Cities.push({value: City.id, label: City.name})
				})
				setCities(Cities)
				setCitiesbyId(CitiesbyId)

			}
		}).catch(function (error) {
			setLoadingCities(0)
		})

	}, [currentCountry, setCurrentCountry]);

	return (
		<>
			<Container>
				<Header back text={Helper.t('FreeCard')}/>
				<Row className={''}>
					<Col md={12}>
						<div className={"pt-5 d-flex justify-content-center"}>
							<img className={'fadeInTop'} style={{width: '14rem'}} srcSet={WrldImg}/>
						</div>
						<div style={{fontSize: '1.5rem'}} className={"pt-1 w-100 d-flex justify-content-center"}>
							{Helper.t('Выбор страны и города')}
						</div>
					</Col>
					<Col md={12} style={{position:'fixed',bottom:'3rem'}}>
						<Form.Group className="mb-3" controlId="formBasicEmail">
							<Form.Label>{Helper.t('Твоя страна и валюта')}</Form.Label>
							<Select  classNamePrefix={"selectReact"} className={"select-lgD"}
									value={currentCountry}
									onChange={setCurrentCountry}
									options={countrys}
									menuPlacement={'top'}
									/>

						</Form.Group>

						<Form.Group className="mb-3" controlId="formBasicPassword">
							<Form.Label>{Helper.t('Город')}</Form.Label>
							<Select  classNamePrefix={"selectReact"} className={"select-lgD"}
									value={currentCity}
									onChange={setCurrentCity}
									options={cities}
									isLoading={isLoadingCities}
									menuPlacement={'top'}
									/>
						</Form.Group>
						<Button variant="warning" type="button" className={"w-100 fw-bolder"}
								onClick={() => {
									Helper.Cookies.set('city_default',currentCity.value)
									Helper.Cookies.set('country_default',currentCountry.value)
									Helper.Cookies.set('country_data',countrysbyId[currentCountry.value])
									Helper.Cookies.set('city_data',citiesbyId[currentCity.value])
									Helper.Cookies.set('firstLoad',true)
									navigate('/')
								}}

						>
						<div></div>
							{Helper.t('Подтвердить')}
						</Button>
					</Col>
				</Row>
			</Container>

		</>
	);
}

export default withTranslation()(Country);
