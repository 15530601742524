import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Cookies from 'js-cookie'
import Storage from './Storage'
import ls from 'local-storage'
// don't want to use this?
// have a look at the Quick start guide
// for passing in lng and translations on init

const resources = {
	ru: {
		translation: require('../locales/ru/translation.json')
	},
	en: {
		translation: require('../locales/en/translation.json')
	}
};


if(Storage.get('lang')){
	var Lang_set = Storage.get('lang')
}else{
	Storage.set('lang','ru')
	var Lang_set = 'ru';
}

i18n
	.use(initReactI18next) // passes i18n down to react-i18next
	.init({
		resources,
		lng: Lang_set, // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
		// you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
		// if you're using a language detector, do not define the lng option

		interpolation: {
			escapeValue: false // react already safes from xss
		}
	});


export default i18n;