import React, {Fragment, useState} from "react";
import "./Alert.css"
import Helper from "../../helpers/Helper"
import store from "../../store";
import {alertHide} from "../../reducers/Alert/init";

function Btns(data) {
	return (
		<></>
	)
}

function Alert(data) {
	const [show, setShow] = useState(0);
	const [text, setText] = useState(0);
	const [title, setTitle] = useState(0);
	const [btns, setBtns] = useState([]);
	Helper.store.subscribe(() => {
		setShow(Helper.store.getState().Alert.show)
		setTitle(Helper.store.getState().Alert.title)
		setText(Helper.store.getState().Alert.text)
		setBtns(Helper.store.getState().Alert.btns)
	})
	const Buttons = Object.keys(btns).map((key) => {
			const val = btns[key];
			return (
				<div
					className={"button" + (val.color && ['success', 'danger'].includes(val.color) ? ' button_' + val.color : '')+(btns.length == 2 ? ' btn_split_'+key : '')}
					onClick={() => {
						if (val.callback && typeof val.callback == "function") {
							val.callback()
							if (val.close) {
								Helper.alertHide()
							}
						} else {
							Helper.alertHide()
						}
					}}>
					{val.name ? val.name : 'OK'}
				</div>
			)
		})
	;
	return (
		<div className={"alert_back" + (show ? '' : ' d-none')}>
			<div className="alert_ios">
				<div className="inner">
					<div className="title">{title}</div>
					<div className="text">{text}</div>
				</div>
				{Buttons}
				{btns.length == 0
				? <div className="button" onClick={() => Helper.alertHide()}>OK</div>
				: <></>}
			</div>
		</div>

	);
}

export default Alert;
