import React, {Fragment} from "react";
import {Link, useLocation, useNavigate} from "react-router-dom";
import {withTranslation} from "react-i18next";
import {Col,Card} from "react-bootstrap";
import "./UserCouponMini.css"
import Helper from "../../../helpers/Helper";
import Moment from "react-moment";
import iconMail from "../../../files/img/profile/coupons/mail.svg"
import iconPrint from "../../../files/img/profile/coupons/print.svg"
import iconPdf from "../../../files/img/profile/coupons/pdf.svg"
import {CustomPlaceholder} from "react-placeholder-image";
import { run as runHolder } from 'holderjs/holder';
class UserCouponMini extends React.Component {
	constructor(props) {
		super(props);
		this.runHolder = runHolder;
	}

	state = {
		key: 0,
		data: {
			Фото: "",
			Купоны: [{ДатаОкончанияКупонов: 0}],
			НомерКупона:0,
			НазваниеКупона:""
		}
	};

	async componentDidMount() {

		console.log("componentDidMount")
		console.log(this.props.data[Object.keys(this.props.data)[0]])
		this.setState({key: Object.keys(this.props.data)[0]})
		this.setState({data: this.props.data[Object.keys(this.props.data)[0]]})
		this.runHolder('image-class-name-no-initial-dot');
	}

	render() {
		return (
			<>
				<Col xs={12} sm={12} md={12} lg={12} className={'mb-4 UserCouponMini'}>
					<Col xs={5} sm={5} md={5} lg={5} className={"colm"}>
						<div className={"coupon-image"}>
							<img className={"image-class-name-no-initial-dot"} srcSet={Helper.ResourceURL + this.state.data.Фото}/>
							<div className={"expired-before"}>
								<div className={"text"}>
									Использовать до <Moment unix format="DD.MM.YYYY">{this.state.data.Купоны[0].ДатаОкончанияКупонов}</Moment>
								</div>

							</div>
						</div>
						<div className={"kod-text"}>Код купона</div>
						<div className={"kod"}>{this.state.data.НомерКупона}</div>
					</Col>
					<Col xs={5} sm={5} md={5} lg={5} className={"colm"}>
						<div className={"coupon-name ps-2"}>{this.state.data.НазваниеКупона}</div>
					</Col>

					<Col xs={2} sm={2} md={2} lg={2} className={"colm"}>
						<div className={"icons"}>
							<div className={"pb-2"}><img srcSet={iconMail}/></div>
							<div className={"pb-2 pt-2"}><img srcSet={iconPrint}/></div>
							<div className={"pt-2"}><img srcSet={iconPdf}/></div>
						</div>
					</Col>


				</Col>
			</>
		);
	}
}

function WithNavigate(props) {
	return (
		<UserCouponMini navigate={useNavigate()} location={useLocation()} {...props} />
	);
}

export default withTranslation()(WithNavigate);
