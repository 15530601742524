import React, {Fragment, useEffect, useState} from "react";
import Header from "../../components/design/Header";
import Helper from "../../helpers/Helper";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAngleRight} from '@fortawesome/free-solid-svg-icons';
import WrldImg from '../../files/img/icons/world_empty.png';
import {withTranslation} from 'react-i18next';
import {useNavigate} from "react-router-dom";
import Container from 'react-bootstrap/Container';

import {Navbar,Col,Row} from "react-bootstrap";
function Lang() {
	let navigate = useNavigate();
	const LangMap = Object.keys(Helper.langs).map((number) =>
		<li
			onClick={() => {
				Helper.setlang(Helper.langs[number].code)
				navigate('/first/country');
			}}
		>{Helper.langs[number].name}<FontAwesomeIcon style={{color: '#EED033'}} icon={faAngleRight}/>
		</li>
	);
	return (
		<>
			<Container >
				<Header back text={Helper.t('FreeCard')} />
				<Row className={''}>
					<Col md={12}>
						<div className={"pt-5 d-flex justify-content-center"}>
							<img className={'fadeInTop'} style={{width: '6rem'}} srcSet={WrldImg}/>
						</div>
						<div className={""}>
							<div className={"StartSelectLang fadeInBottom"}>
								<ul>
									{LangMap}
								</ul>
							</div>
						</div>

					</Col>
				</Row>
			</Container>

		</>
	);
}

export default withTranslation()(Lang);
