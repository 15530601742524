import React, { Fragment } from 'react'
import { Routes, Route, Navigate, useLocation } from 'react-router-dom'
import { isExpired, decodeToken } from "react-jwt";
import Helper from "./Helper";
import {useNavigate} from "react-router-dom";
function FirstLoad({ children }: { children: JSX.Element }) {
	console.log('FirstLoad');
	let navigate = useNavigate();
	let token = Helper.Storage.get('token')
	if (!Helper.Storage.get('firstLoad')) {
		navigate("/first");
		//return <Navigate to="/first" />
	}
	return children
}

export default FirstLoad
