import React, {Fragment, useEffect, useState} from "react";
import Helper from "../../helpers/Helper";
import {withTranslation} from 'react-i18next';
import Container from "react-bootstrap/Container";
import Header from "../../components/design/Header";
import {Col, Row} from "react-bootstrap";
import {useNavigate} from "react-router-dom";

function Start() {
	const navigate = useNavigate();
	const hellows = [
		Helper.t('Hello'),
		Helper.t('Hola'),
		Helper.t('Hallo'),
		Helper.t('Cześć'),
		Helper.t('Olá'),
		Helper.t('Ciao'),
		Helper.t('Bonjour'),
		Helper.t('Здравствуйте'),
		Helper.t('Привіт'),
	];
	const [Shellows, setShellows] = useState(0);
	const [WelcomeText, setWelcomeText] = useState(WelcomeTextWrap());
	const [SClass, setSClass] = useState('fadeOut');

	useEffect(() => {
		clearInterval(window.FadeText);
		window.FadeText = setInterval(function () {
			if (SClass == '' || SClass == 'fadeIn') {
				setSClass('fadeOut')
			} else {
				var NewShellows = (Shellows + 1 > hellows.length) ? 0 : Shellows + 1
				setSClass('fadeIn')
				setShellows(NewShellows)
			}

		}, 1500);
	});

	function WelcomeTextWrap() {
		return (
			<div>{hellows[Shellows]}</div>
		);
	}

	return (
		<>
			<Header text={Helper.t('FreeCard')}/>
			<Container style={{position: 'absolute', width: '100%', height: '100%', top: '0'}}>
				<Row className={'h-100'}>
					<Col md={12} className={'h-100 p-0'}>
						<div className={'StartBody h-100 d-flex justify-content-center ' + SClass}
							 onClick={() => {
								 navigate('/first/lang')
							 }}
						>
							{WelcomeTextWrap()}
						</div>
						<div
						className={"w-100 d-flex justify-content-center"}
							style={{
								position: "absolute",
								bottom: '1rem',
							}}
						>
							<div style={{
								backgroundColor: '#EED033',
								border: '0.15rem solid rgb(238, 208, 51)',
								width: '7rem'
							}}></div>
						</div>
					</Col>
				</Row>
			</Container>
		</>
	);
}

export default withTranslation()(Start);
