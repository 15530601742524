import React, { Fragment } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {withTranslation} from "react-i18next";

class Freecard extends React.Component {
	constructor(props) {
		super(props);
	}
	state = {

	};

	async componentDidMount() {

	}
	render() {
		return (
			<>

			</>
		);
	}
}
function WithNavigate(props) {
	return (
		<Freecard navigate={useNavigate()} location={useLocation()} {...props} />
	);
}
export default withTranslation()(WithNavigate);
