import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import Helper from "./helpers/Helper";
import {Routes, Route, Navigate, useLocation, matchRoutes} from 'react-router-dom'
import Alert from "./components/helpers/Alert";
import {default as PageFirstLoad} from "./pages/FirstLoad/FirstLoad" ;
import {default as StartLang} from "./pages/FirstLoad/Lang";
import {default as StartCountry} from "./pages/FirstLoad/Country";
import Start from "./pages/Start";
import FirstLoad from "./helpers/FirstLoad";
import RequireAuth from "./helpers/Auth";
import SelectAuth from "./pages/Auth/SelectAuth";
import Login from "./pages/Auth/Login";
import Cards from "./pages/cards/Cards";
import Card from "./pages/cards/Card";
import Getcashback from "./pages/getcashback/Getcashback";
import Goods from "./pages/goods/Goods";
import Good from "./pages/goods/Good";
import ProfileMenu from "./pages/Profile/Menu/ProfileMenu";
import Register from "./pages/Register/Register";
import Freecard from "./pages/Freecard/Freecard";
import Map from "./pages/Map/Map";
import Osago from "./pages/Osago/Osago";


function NotFound() {
	console.log("!!!!!!!!!!!!!");
	return null;
}

function App() {
	return (
		<>
			<Alert/>
			<Routes>

				<Route
					path="/guest"
					element={
						<Cards role={'guest'}/>
					}
				/>

				<Route
					path="/guest/card/:id"
					element={
						<Card role={'guest'}/>
					}
				/>

				<Route
					path="/user"
					element={
						<Cards role={'user'}/>
					}
				/>

				<Route
					path="/user/card/:id"
					element={
						<Card role={'user'}/>
					}
				/>

				<Route
					path="/user/freecard"
					element={
						<Freecard />
					}
				/>

				<Route
					path="/getcashback"
					element={
						<Getcashback />
					}
				/>

				<Route
					path="/map"
					element={
						<Map />
					}
				/>

				<Route
					path="/osago"
					element={
						<Osago />
					}
				/>

				<Route
					path="/goods"
					element={
						<Goods />
					}
				/>

				<Route
					path="/goods/:id"
					element={
						<Good/>
					}
				/>

				<Route
					path="/auth/select"
					element={
						<SelectAuth/>
					}
				/>

				<Route
					path="/auth/login"
					element={
						<Login/>
					}
				/>

				<Route
					path="/auth/register"
					element={
						<Register/>
					}
				/>

				<Route
					path="/"
					element={
						<FirstLoad>
							<RequireAuth>
								<Start/>
							</RequireAuth>
						</FirstLoad>
					}
				/>

				<Route
					path="/index.html"
					element={
						<FirstLoad>
							<RequireAuth>
								<Start/>
							</RequireAuth>
						</FirstLoad>
					}
				/>

				<Route
					path="/first"
					element={
						<PageFirstLoad/>
					}
				/>

				<Route
					path="/first/lang"
					element={
						<StartLang/>
					}
				/>

				<Route
					path="/first/country"
					element={
						<StartCountry/>
					}
				/>
				<Route path="/profile" element={<ProfileMenu />} />
				<Route path="*" element={<NotFound />} />
			</Routes></>
	);
}

export default App;
