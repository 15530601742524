import React, {Fragment} from "react";
import {Link, useLocation, useNavigate} from "react-router-dom";
import {withTranslation} from "react-i18next";
import Header from "../../components/design/Header";
import Helper from "../../helpers/Helper";
import {Col, Row, Container, Button} from "react-bootstrap";
import FreecardImg from "../../files/img/icons/freeCardImg.png";

class SelectAuth extends React.Component {
	constructor(props) {
		super(props);
	}

	state = {};

	async componentDidMount() {

	}

	render() {
		return (
			<>
				<Container>
					<Header text={Helper.t('Персональная FreeCard')}/>
					<Row className={''}>
						<Col md={12}>
							<div className={"pt-5 d-flex justify-content-center"}>
								<img className={'fadeInTop'} style={{width: '80%', maxWidth: '300px'}}
									 srcSet={FreecardImg}/>
							</div>

							<div className={"pt-5 d-flex justify-content-center"}>
								<Button variant="warning" type="button" className={"w-100 fw-bolder"}
										onClick={() => this.props.navigate('/auth/register')}>{Helper.t('Получить FreeCard')}</Button>
							</div>

							<div className={"pt-3 d-flex justify-content-center"}>
								<Button variant="link" type="button" className={"w-100 text-warning fw-bolder"}
										onClick={() => {
										this.props.navigate('/auth/login')
										}}>{Helper.t('Войти')}</Button>
							</div>

							<div className={"pt-3 d-flex justify-content-center"}>
								<Button variant="link" type="button" className={"w-100 text-warning fw-bolder"}
										onClick={() => {
										Helper.Cookies.set('user_role','guest')
										Helper.Cookies.set('token','')
										this.props.navigate('/guest')
										}}>{Helper.t('Войти как гость')}</Button>
							</div>


						</Col>
					</Row>
				</Container>
			</>
		);
	}
}

function WithNavigate(props) {
	return (
		<SelectAuth navigate={useNavigate()} location={useLocation()} {...props} />
	);
}

export default withTranslation()(WithNavigate);
