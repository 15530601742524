import React, {Fragment} from "react";
import {Link, useLocation, useNavigate} from "react-router-dom";
import {withTranslation} from "react-i18next";
import "./UserFavourites.css";
import Helper from "../../../helpers/Helper";
import FavActions from "./Types/FavActions";
import ShopMini from "../../../components/catalog/getcashback/ShopMini";
import GoodMini from "../../../components/catalog/goods/GoodMini";
import {Row} from "react-bootstrap";
import Container from "react-bootstrap/Container";


class UserFavourites extends React.Component {
	constructor(props) {
		super(props);
	}

	state = {
		goods: [],
		goods_ids: [],
	};

	async componentDidMount() {
		Helper.UpdateFavourites()
		var fav = Helper.store.getState().favourites
		var self = this;
		Helper.store.subscribe(() => {
			var fav = Helper.store.getState().favourites
			console.log('Helper.store.subscribe')
			console.log(fav)
			if (fav.goods) {
				var ids = [];
				//this.setState({goods: fav.goods})
				for (let item in fav.goods) {
					ids.push(item)
				}
				self.setState({goods_ids: ids})
				if (ids.length > 0) {
					Helper.API.get('/catalog/goods?ids=' + ids.join(',')).then((response) => {
						if (response.data.status) {
							self.setState({goods: response.data.data.data})
						}
					}).catch(function (error) {

					})
				}
			}
		})

	}

	render() {
		return (
			<>
				<Container className={"page-goods"}>
					<Row className={"mt-3 pb-5"}>
						{this.state.goods.map((item) => {
							return (
								<GoodMini data={item}/>
							)

						})}
					</Row>
				</Container>
			</>
		);
	}
}

function WithNavigate(props) {
	return (
		<UserFavourites navigate={useNavigate()} location={useLocation()} {...props} />
	);
}

export default withTranslation()(WithNavigate);
