// Код файла store.js
import {legacy_createStore as createStore} from 'redux'
import {alertShow,alertHide,ALERT_SHOW,ALERT_HIDE} from "./reducers/Alert/init"
import {favourites,FAVOURITES} from "./reducers/Favourites/Init";

const TEST = 'TEST';

const store = createStore(reducer);

function reducer(state, action) {
	switch (action.type) {
		case ALERT_SHOW:
			return {...state,Alert: {show:1,text:action.text,title:action.title,btns:action.btns}};
		case ALERT_HIDE:
			return {...state,Alert: {show:0,btns: []}};
		case TEST:
			return {...state,valuesss: action.valuesss};
		case FAVOURITES:
			return {...state,favourites: action.data};

		default:
			return state;
	}
}

store.dispatch({ type: 'ALERT_HIDE' })
store.dispatch({ type: 'FAVOURITES', favourites:{cashback:[],offers:[],insurance_offers:[],biz_offers:[],goods:[],action:[]}})
store.dispatch({ type: 'TEST',valuesss:0 })
console.log(store.getState());
export default store;