import React, {Fragment} from "react";
import {Link, useLocation, useNavigate} from "react-router-dom";
import {withTranslation} from "react-i18next";
import Container from "react-bootstrap/Container";
import {Button, Col, Row} from "react-bootstrap";
import "./UserCashbackOnlineOrderMini.css"
import Moment from "react-moment";
import Helper from "../../../../helpers/Helper";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

import {faCircleInfo} from '@fortawesome/free-solid-svg-icons'
class UserCashbackOnlineOrderMini extends React.Component {
	constructor(props) {
		super(props);
	}

	state = {};

	async componentDidMount() {

	}

	render() {
		return (
			<>
				<Col xs={12} sm={12} md={12} lg={12} className={'mb-4 p-2 UserCashbackOnlineMini'}>
					<div className={"first"}>
						<div className={"left"}>[№{this.props.data.id}] №{this.props.data.order_id}</div>
						<div className={"right"}>Сумма: {this.props.data.sum}₽<FontAwesomeIcon  onClick={e=>Helper.Alert('Сумма покупки',' ')} className={"ps-1"} icon={faCircleInfo} /></div>
					</div>
					<div className={"second"}>
						<div className={"left"}><Moment unix format="DD.MM.YYYY HH:MM">{this.props.data.create_time}</Moment><FontAwesomeIcon  onClick={e=>Helper.Alert('Дата создания',' ')} className={"ps-1"} icon={faCircleInfo} /></div>
						<div className={"right"}><Moment unix format="DD.MM.YYYY HH:MM">{this.props.data.update_time}</Moment><FontAwesomeIcon  onClick={e=>Helper.Alert('Дата обновления',' ')} className={"ps-1"} icon={faCircleInfo} /></div>
					</div>
					<div className={"third"}>
						<div className={"left "+((!this.props.data.image) ? 'no-image' : '')}>
							{this.props.data.image &&
							<img srcSet={this.props.data.shop.image}/>
							}
							{!this.props.data.image &&
								<span>Нет изображения</span>
							}
						</div>
						<div className={"right"}>
							{this.props.data.status == 'approved_not_is_paid' && this.props.data.status !== 'declined' && this.props.data.shop && this.props.data.shop.fast_cashback == 1 &&
								<div className={"top tgetfast"}><Button variant="warning">Получить сейчас</Button></div>
							}
							{this.props.data.status == 'declined' &&
								<div className={"top tdeclined"}>Отказано</div>
							}
							<div className={"top tsuccess"}>Зачислено</div>
							<div className={"bottom"}>{this.props.data.cashback_base}₽<FontAwesomeIcon  onClick={e=>Helper.Alert('Ваш кэшбэк',' ')} className={"ps-1"} icon={faCircleInfo} /></div>
						</div>
					</div>
				</Col>
			</>
		);
	}
}

function WithNavigate(props) {
	return (
		<UserCashbackOnlineOrderMini navigate={useNavigate()} location={useLocation()} {...props} />
	);
}

export default withTranslation()(WithNavigate);
