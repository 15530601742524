import React, {Fragment} from "react";
import {Link, useLocation, useNavigate} from "react-router-dom";
import {withTranslation} from "react-i18next";
import "./ProfileMenu.css"
import Container from "react-bootstrap/Container";
import {Col, Row, Image, Button, Badge} from "react-bootstrap";
import Helper from "../../../helpers/Helper";
import gearIcon from "../../../files/img/profile/gear.svg"
import logoutIcon from "../../../files/img/profile/logout.svg"
import profileIcon from "../../../files/img/profile/profile.svg";
import favIcon from "../../../files/img/profile/fav.svg";
import percentIcon from "../../../files/img/profile/percent.svg";
import cashbackIcon from "../../../files/img/profile/cashback.svg";
import marketingIcon from "../../../files/img/profile/marketing.svg";
import cartIcon from "../../../files/img/profile/cart.svg";
import receiptsIcon from "../../../files/img/profile/receipt.svg";
import promoIcon from "../../../files/img/profile/promo.svg";
import withIcon from "../../../files/img/profile/with.svg";
import gear2Icon from "../../../files/img/profile/gear2.svg";
import diamondIcon from "../../../files/img/profile/diamond.svg";
import Footer from "../../../components/design/Footer";
import ExternalPage from "../../../components/Helper/ExternalPage";
import Good from "../../goods/Good";
import Favourites from "../../../components/helpers/Favourites";
import UserFavourites from "../Fav/UserFavourites";
import UserCoupons from "../Coupons/UserCoupons";
import UserCashback from "../Cashback/UserCashback";
import UserMarketing from "../Marketing/UserMarketing";
import Profile from "../Profile/Profile";

class ProfileMenu extends React.Component {
	constructor(props) {
		super(props);
		this.CloseFavouritesPage = this.CloseFavouritesPage.bind(this);
		this.CloseCouponsPage = this.CloseCouponsPage.bind(this);
		this.CloseCashbackPage = this.CloseCashbackPage.bind(this);
		this.CloseMarketingPage = this.CloseMarketingPage.bind(this);
		this.CloseProfilePage = this.CloseProfilePage.bind(this);
	}

	state = {
		data: {
			avatar: "",
			email: "",
			status_names: ["", "", ""],
			balances: {
				balance: 0,
				balanceBONUS: 0,
				balanceCB: 0,
				balanceCBF: 0,
				balanceFC: 0,
				balanceFull: 0,
				balanceNoFull: 0,
				balanceREF: 0,
				checkCashback: 0,
				marketingTotal: 0,
				payments: 0
			}
		},
		favCount:0,
		FavouritesPage: "",
		CouponsPage: "",
		CashbackPage: "",
		MarketingPage: "",
		ProfilePage: ""
	}
	;

	async CloseFavouritesPage() {
		this.setState({FavouritesPage: ''})
	}

	async OpenFavouritesPage(data) {
		this.setState({FavouritesPage: data})
	}
	async CloseCouponsPage() {
		this.setState({CouponsPage: ''})
	}

	async OpenCouponsPage(data) {
		this.setState({CouponsPage: data})
	}
	async CloseCashbackPage() {
		this.setState({CashbackPage: ''})
	}

	async OpenCashbackPage(data) {
		this.setState({CashbackPage: data})
	}
	async CloseMarketingPage() {
		this.setState({MarketingPage: ''})
	}

	async OpenMarketingPage(data) {
		this.setState({MarketingPage: data})
	}
	async CloseProfilePage() {
		this.setState({ProfilePage: ''})
	}

	async OpenProfilePage(data) {
		this.setState({ProfilePage: data})
	}

	async componentDidMount() {
		Helper.UpdateFavourites()
		Helper.store.subscribe(() => {
			var favs = Helper.store.getState().favourites
			var favCount = 0;
			favCount += (favs.cashback) ? Object.keys(favs.cashback).length : 0
			favCount += (favs.offers) ? Object.keys(favs.offers).length : 0
			favCount += (favs.biz_offers) ? Object.keys(favs.biz_offers).length : 0
			favCount += (favs.goods) ? Object.keys(favs.goods).length : 0
			favCount += (favs.action) ? Object.keys(favs.action).length : 0
			this.setState({favCount:favCount})
		})
		Helper.API.get('/profile').then((response) => {
			if (response.data.status) {
				console.log(response.data.data)
				this.setState({data: response.data.data})
			}
		}).catch(function (error) {

		})
	}

	render() {
		return (
			<>
				<Container className={"page-profileMenu"}>
					{this.state.FavouritesPage}
					{this.state.CouponsPage}
					{this.state.CashbackPage}
					{this.state.MarketingPage}
					{this.state.ProfilePage}
					<Row className={"mt-3 pb-2"}>
						<Col>
							<div className={"topColumn"}>
								<Image className={"avatar"} style={{height: "3rem"}}
									   srcSet={Helper.ResourceURL + this.state.data.avatar}
									   roundedCircle/>
								<div className={"usernameEmail"}>
									{this.state.data.email}</div>
								<div className={"btns"}>
									<Button variant="secondary" className={"p-2"}><img srcSet={gearIcon}/></Button>
									<Button variant="secondary" className={"p-2"} onClick={(e)=>{
										Helper.Cookies.clear()
										this.props.navigate('/')
									}}><img srcSet={logoutIcon}/></Button>
								</div>
							</div>

						</Col>
					</Row>
					<Row className={"mt-1 pb-1"}>
						<Col>
							<div
								className={"statusname"}>Статус: <b>{this.state.data.status_names[0]}(+{this.state.data.status_names[2]}%)</b>
							</div>
						</Col>
					</Row>
					<Row className={"mt-3 pb-2"}>
						<Col className={"links"}>
							<p className={"m-0 mb-2"} onClick={(e) => {
								this.OpenProfilePage(<ExternalPage title={"Мой профиль"} data={<Profile/>}
																	  callback={this.CloseProfilePage}/>)
							}}><img className={"me-2"} srcSet={profileIcon}/>Мой профиль</p>
							<p className={"m-0 mb-2"} onClick={(e) => {
								this.OpenFavouritesPage(<ExternalPage data={<UserFavourites/>}
																	  callback={this.CloseFavouritesPage}/>)
							}}><img className={"me-2"} srcSet={favIcon}/>Избранное<Badge bg="danger"
																						 className={"ms-1 p-1"}>{this.state.favCount}</Badge>
							</p>
							<p className={"m-0 mb-2"} onClick={(e) => {
								this.OpenCouponsPage(<ExternalPage data={<UserCoupons/>}
																	  callback={this.CloseCouponsPage}/>)
							}}><img className={"me-2"} srcSet={percentIcon}/>Мои купоны</p>
							<p className={"m-0 mb-2"} onClick={(e) => {
								this.OpenCashbackPage(<ExternalPage title={"Мой Кэшбэк"}  data={<UserCashback/>}
																   callback={this.CloseCashbackPage}/>)
							}}><img className={"me-2"} srcSet={cashbackIcon}/>Мой
								кэшбэк {this.state.data.balances.balanceCB}&nbsp;₽&nbsp;/&nbsp;{this.state.data.balances.balanceCBF}&nbsp;₽
							</p>
							<p className={"m-0 mb-2"} onClick={(e) => {
								this.OpenMarketingPage(<ExternalPage title={"Задания"}  data={<UserMarketing/>}
																	callback={this.CloseMarketingPage}/>)
							}}><img className={"me-2"} srcSet={marketingIcon}/>Мой маркетинг</p>
							<p className={"m-0 mb-2"}><img className={"me-2"} srcSet={cartIcon}/>Корзина</p>
							<p className={"m-0 mb-2"}><img className={"me-2"} srcSet={receiptsIcon}/>Мои чеки</p>
							<p className={"m-0 mb-2"}><img className={"me-2"} srcSet={promoIcon}/>Промокоды</p>
							<p className={"m-0 mb-2"}><img className={"me-2"}
														   srcSet={cashbackIcon}/>Баланс {this.state.data.balances.balance + this.state.data.balances.payments}&nbsp;₽&nbsp;/&nbsp;{this.state.data.balances.balanceCB + this.state.data.balances.balanceREF + this.state.data.balances.balanceFC + this.state.data.balances.balanceBONUS + this.state.data.balances.marketingTotal + this.state.data.balances.checkCashback}&nbsp;₽
							</p>
							<p className={"m-0 mb-2"}><img className={"me-2"} srcSet={withIcon}/>Вывод средств</p>
							<p className={"m-0 mb-2"}><img className={"me-2"} srcSet={gear2Icon}/>Техподдержка</p>
							<p className={"m-0 mb-2"}><img className={"me-2"} srcSet={diamondIcon}/>Кабинет партнера</p>
						</Col>
					</Row>
				</Container>
				<Footer profile/>
			</>
		);
	}
}

function WithNavigate(props) {
	return (
		<ProfileMenu navigate={useNavigate()} location={useLocation()} {...props} />
	);
}

export default withTranslation()(WithNavigate);
