import React, {Fragment} from "react";
import {Link, useLocation, useNavigate} from "react-router-dom";
import {withTranslation} from "react-i18next";
import "./MarketingJob.css"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Button, Col, Card, InputGroup, Form, Spinner} from "react-bootstrap";
import Moment from "react-moment";
import Helper from "../../../../helpers/Helper";
import {faPaperPlane, faBarsSort} from "@fortawesome/free-solid-svg-icons";
import ExternalPage from "../../../../components/Helper/ExternalPage";
import MarketingReport from "../Report/MarketingReport";

class MarketingJob extends React.Component {
	constructor(props) {
		super(props);
		this.CloseReportPage = this.CloseReportPage.bind(this);
	}
	
	state = {
		data: {},
		msg: "",
		sendMsgBtnActive: true,
		ReportPage:""
	};
	

	async sendMessage() {
		if (this.state.msg == '') {
			return false
		}
		this.setState({sendMsgBtnActive: false})
		Helper.API.post("/profile/marketing/task/message/" + this.props.data.id, {msg: this.state.msg}).then((response) => {
			if (response.data.status) {
				Helper.Alert(response.data.data)
				this.GetJob();
			}
			this.setState({sendMsgBtnActive: true})
		}).catch(function (error) {
			this.setState({sendMsgBtnActive: true})
		})
	}
	
	async GetJob() {
		Helper.API.get("/profile/marketing/task/" + this.props.data.id).then((response) => {
			if (response.data.status) {
				
				this.setState({data: response.data.data[0]})
				
			}
		}).catch(function (error) {
		
		})
	}
	
	async componentDidMount() {
		this.GetJob();
	}
	
	async copyToClipboard(text) {
		if (window.clipboardData && window.clipboardData.setData) {
			// Internet Explorer-specific code path to prevent textarea being shown while dialog is visible.
			return window.clipboardData.setData("Text", text);
			
		} else if (document.queryCommandSupported && document.queryCommandSupported("copy")) {
			var textarea = document.createElement("textarea");
			textarea.textContent = text;
			textarea.style.position = "fixed";  // Prevent scrolling to bottom of page in Microsoft Edge.
			document.body.appendChild(textarea);
			textarea.select();
			try {
				return document.execCommand("copy");  // Security exception may be thrown by some browsers.
			} catch (ex) {
				console.warn("Copy to clipboard failed.", ex);
				return prompt("Copy to clipboard: Ctrl+C, Enter", text);
			} finally {
				document.body.removeChild(textarea);
			}
		}
	}
	
	async CloseReportPage(exit=false) {
		this.setState({ReportPage: ''})
		if(exit){
			this.props.callback(true);
		}
	}
	async OpenReportPage(data) {
		this.setState({ReportPage: data})
	}
	render() {
		return (
			<>
				{this.state.ReportPage}
				<Col xs={12} sm={12} md={12} lg={12} className={'mb-4 p-0 UserMarketingJob'}>
					
					<div className={"pall text-center p-2"}>Оплачиваемое задание</div>
					<div className={"stext p-2"}>
						Название: <b>{this.state.data.name}</b>
					</div>
					<div className={"stext p-2"}>
						Тип задания: <b>{this.state.data.type}</b>
					</div>
					<div className={"stext p-2"}>
						Рекламодатель: <b>{this.state.data.AuthorName}</b>
					</div>
					<div className={"stext p-2"}>
						Проверка задания: <b>{this.state.data.check_task == 1 ? "В ручном режиме" : "Автоматически"}</b>
					</div>
					<div className={"stext p-2"}>
						Повтор
						задания: <b>{this.state.data.repeated_execution == 0 ? "моментально(без задержки)" : ((this.props.data.repeated_execution == 999) ? "Повторно выполнить задание невозможно" : "Повторно выполнить задание " + this.props.data.repeated_execution + " час")}</b>
					</div>
					<div className={"stext p-2"}>
						Время выполнения: <b>{this.state.data.time_to_complete} минут</b>
					</div>
					<div className={"stext p-2"}>
						Вознаграждение за выполнение: <b>{this.state.data.cost} ₽</b>
					</div>
					<div className={"pall text-center p-2"}>Описание задания</div>
					
					<div className={"stext p-2"} dangerouslySetInnerHTML={{__html: this.state.data.detail}}/>
					
					<div className={"pall text-center p-2"}>Выполнение задания</div>
					
					{this.state.data.WorkerStatus == "working" && this.state.data.taskStatus != "on_check" &&
						<>
							<div className={"stext p-2"}>
								<Button size="sm" style={{width: "100%"}} variant="secondary" onClick={(e) => {
									this.copyToClipboard(this.state.data.url)
								}}>Скопировать ссылку в буфер обмена</Button>
							</div>
							<div className={"stext p-2"}>
								<Button href={this.state.data.url} target={"_blank"} size="sm" style={{width: "100%"}}
										variant="secondary">Перейти по ссылке</Button>
							</div>
							<div className={"stext p-2"}>
								Задание активно до: <Moment
								format="DD.MM.YYYY HH:MM">{this.state.data.WorkerFinish}</Moment>
							</div>
						</>
						
					}
					<div className={"stext p-2"}>
						{this.state.data.taskStatus == "on_check" &&
							<>Задание ожидает проверку рекламодателем, в течение 5 дней.</>
						}
						{this.state.data.taskStatus == "done" &&
							<>Задание выполнено, а денежные средства зачислены на ваш баланс. Вы можете выполнить
								задание повторно {this.props.data.WorkerCanRepeat}.</>
						}
						{this.state.data.taskStatus == "not_performed" &&
							<>Задание не выполнено. Вы можете выполнить задание
								повторно <span> {this.props.data.WorkerCanRepeat} </span>.</>
						}
						{this.state.data.taskStatus == "send_for_revision" &&
							<>Задание отправлено на доработку, просим вас исправить все недочеты в комментариях.</>
						}
					</div>
					{this.state.data.taskStatus == "send_for_revision" &&
						<>
							<div className={"pall text-center p-2"}>Отправленный вами текст для проверки задания</div>
							{this.state.data.taskReport &&
								
								<div className={"stext p-2"}>
									{this.state.data.taskReport}
								</div>
								
							}
						</>
					}
					{this.state.data.taskStatus == "send_for_revision" &&
						<>
							<div className={"pall text-center p-2"}>Переписка с рекламодателем</div>
							{
								this.state.data.messages.map((e) => {
									return (
										<div
											className={"ps-2 pe-2 pb-1 pt-1 " + ((e.user_id == 'admin') ? "admin-msg" : "msg")}>
											<Card style={{width: "85%"}} className={" "} body>{e.message}</Card>
										</div>
									)
								})
							}
							<div className={"ps-2 pe-2 pb-1 pt-2"}>
								<InputGroup className="mb-3">
									<Form.Control
										placeholder="Ваш ответ"
										aria-describedby="basic-addon2"
										value={this.state.msg}
										onChange={(e) => {
											this.setState({msg: e.target.value})
										}}
									
									/>
									<Button variant="light" id="button-addon2"
											disabled={!this.state.sendMsgBtnActive}
											onClick={(e) => {
												this.sendMessage();
											}}
									>
										{this.state.sendMsgBtnActive
											? <FontAwesomeIcon icon={faPaperPlane}/>
											: <Spinner
												as="span"
												animation="border"
												size="sm"
												role="status"
												aria-hidden="true"
											/>
										}
									</Button>
								</InputGroup>
							</div>
						</>
						
					}
					<div className={"pall text-center p-2"}>Проверка задания</div>
					{this.state.data.WorkerStatus && this.state.data.WorkerStatus == 'working' && this.state.data.taskStatus && this.state.data.taskStatus != "on_check" &&
						<>
							<div className={"stext btn-wrap p-2"}>
								<Button className={"btn-start"} variant={"warning"} onClick={(e) => {
									this.OpenReportPage(<ExternalPage className={"p-0"} title={"Отчет по заданию"}  data={<MarketingReport callback={this.CloseReportPage} data={this.state.data}/>}
																   callback={this.CloseReportPage}/>)
								}}>Я выполнил задание</Button>
							</div>
						</>
					}
					{this.state.data.taskStatus && this.state.data.taskStatus == "on_check" &&
						<div className={"stext p-2"}>
							На проверке
						</div>
					}
					{!this.state.data.taskStatus &&
						<>
							
							{this.state.data.WorkerStatus && this.state.data.WorkerStatus != 'working' &&
								<div className={"stext btn-wrap p-2"}>
									<Button className={"btn-start"} variant={"warning"} onClick={(e) => {
										Helper.API.post('/profile/marketing/task/' + this.state.data.id).then((response) => {
											console.log("CREATE")
											console.log(response)
											console.log("CREATE")
											this.GetJob();
										}).catch(function (error) {
										
										})
									}}>Выполнить задание</Button>
								</div>
							}
						</>
					}
				</Col>
			</>
		);
	}
}

function WithNavigate(props) {
	return (
		<MarketingJob navigate={useNavigate()} location={useLocation()} {...props} />
	);
}

export default withTranslation()(WithNavigate);
