import React, {Fragment} from "react";
import {Link, useLocation, useNavigate} from "react-router-dom";
import {withTranslation} from "react-i18next";
import Topmenu from "../../components/design/Topmenu/Topmenu";
import Container from "react-bootstrap/Container";
import {Button, Col, Row, Form, Card, Placeholder, Spinner, ButtonGroup, DropdownButton, Dropdown} from "react-bootstrap";
import Footer from "../../components/design/Footer";
import "./Goods.css"
import GoodMini from "../../components/catalog/goods/GoodMini";
import Helper from "../../helpers/Helper";
import searchIcon from "../../files/img/icons/search.svg"
import filterIcon from "../../files/img/icons/filter.svg"
import ExternalPage from "../../components/Helper/ExternalPage";
import GoodsFilters from "./Filters/GoodsFilters";

class Goods extends React.Component {
	constructor(props) {
		super(props);
		document.getElementById('root').scrollTop = 0;
		this.SearchSubmit = this.SearchSubmit.bind(this);
		this.CloseFiltersPage = this.CloseFiltersPage.bind(this);
	}

	state = {
		Goods: {
			items: [],
			page: 0,
			pages: 0
		},
		BtnLoading: false,
		filter: {
			search: "",
			shop_id: 0,
			brand: 0,
			search_help:0
		},
		FiltersPage:""
	};
	
	getQueryVariable(variable)
	{
		var query = window.location.search.substring(1);
		var vars = query.split("&");
		for (var i=0;i<vars.length;i++) {
			var pair = vars[i].split("=");
			if(pair[0] == variable){return decodeURI(pair[1]);}
		}
		return(false);
	}
	async LoadNextPage(reload = false) {
		this.setState({BtnLoading: true})
		var self = this
		var page = Number(this.state.Goods.page);
		if (reload) {
			page = 0
		}
		var url = '/catalog/goods?page=' + (page + 1) + '&country_id=' + Helper.Cookies.get('country_default');
		if (this.state.filter.search !== '') {
			url += "&search=" + this.state.filter.search
		}
		if (this.state.filter.search_help !== 0) {
			url += "&search_help=" + this.state.filter.search_help
		}
		if (this.state.filter.brand !== 0) {
			url += "&brand=" + this.state.filter.brand
		}
		Helper.API.get(url).then((response) => {
			if (response.data.status) {
				if (response.data.data.page > this.state.Goods.page) {
					var Goods = this.state.Goods;
					Goods.items = [].concat(Goods.items, response.data.data.data);
					Goods.page = response.data.data.page
					Goods.pages = response.data.data.pages
					this.setState({Goods: Goods})
				}
			}
			this.setState({BtnLoading: false})
		}).catch(function (error) {
			self.setState({BtnLoading: false})
		})
	}

	async checkQuery() {
		var search = this.getQueryVariable("search");
		var brand = this.getQueryVariable("brand");
		var filter = this.state.filter
		
		filter.search = (search) ? search : ""
		filter.brand = (brand) ? brand : 0
		this.setState({filter:filter})
		this.setState({Goods: {items: [], page: 0, pages: 0}})
		this.LoadNextPage(true)
	}
	
	componentDidUpdate(prevProps) {    // using componentDidUpdate because componentWillReceiveProps will be renamed to UNSAFE_componentWillReceiveProps from v16.3.0 and later removed
		
		if(this.props.location.search !== prevProps.location.search) {
			console.log("componentDidUpdate")
			this.checkQuery()
		}
	}
	async componentDidMount() {
		this.checkQuery();
		console.log("this.state.Goods");
		console.log(this.state.Goods);
		console.log("this.state.Goods");
		this.LoadNextPage()
		Helper.UpdateFavourites()

	}
	
	async CloseFiltersPage(){
		this.setState({FiltersPage:''})
	}
	async OpenFiltersPage(data){
		this.setState({FiltersPage:data})
	}
	async SearchSubmit(e) {
		e.preventDefault();
		this.setState({Goods: {items: [], page: 0, pages: 0}})
		this.LoadNextPage(true)
	}

	render() {
		return (
			<>
				<Topmenu/>
				{this.state.FiltersPage}
				<Container className={"page-goods"}>
					<Row className={"mt-3 pb-5"}>
						<ButtonGroup size="sm" className={"mb-3 filter-group"}>
							<Button variant={"outline-light"}><img srcSet={searchIcon}/> Поиск магазинов</Button>
							<Button variant={"outline-light"}><img srcSet={filterIcon}/> Категория: Всё</Button>
							<Button variant={"outline-light"}onClick={(e)=>{
								this.OpenFiltersPage(<ExternalPage title={"Фильтр"} data={<GoodsFilters filter={this.state.filter} />} callback={this.CloseFiltersPage}/>);
							}}><img srcSet={filterIcon}/> Фильтр</Button>
						</ButtonGroup>

						<Form onSubmit={this.SearchSubmit}>
							<Form.Group className="mb-3">
								<Form.Control placeholder={Helper.t("GOODS.Поиск")} value={this.state.filter.search} onKeyUp={(e) => {
									
									var state = this.state.filter
									state.search = e.target.value
									this.setState({filter: state})
								}}/>
							</Form.Group>
						</Form>
						{this.state.Goods.items.length == 0 && this.state.BtnLoading == true &&
							<div className={"loading mb-4"}>
								<Spinner animation="border" role="status">
									<span className="visually-hidden">{Helper.t("Loading")}</span>
								</Spinner>
							</div>
						}
						{this.state.Goods.items.length == 0 && this.state.BtnLoading == false &&
							<div className={"no-found"}>Ничего не найдено</div>
						}
						{this.state.Goods.items.map((item) => {
							return (
								<GoodMini data={item}/>
							)

						})}
						{
							this.state.Goods.page < this.state.Goods.pages
								? <Col><Button variant="warning" disabled={this.state.BtnLoading} onClick={(e) => {
									this.setState({BtnLoading: true})
									this.LoadNextPage()
								}} className={"mb-5"}
											   style={{width: '100%'}}>
									{this.state.BtnLoading ? <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" /> : ''}
									{this.state.BtnLoading ? ' '+Helper.t("Loading")+'...' : Helper.t("LoadingMore")}
								</Button></Col>
								: ''
						}

					</Row>
				</Container>
				<Footer search/>
			</>
		);
	}
}

function WithNavigate(props) {
	return (
		<Goods navigate={useNavigate()} location={useLocation()} {...props} />
	);
}

export default withTranslation()(WithNavigate);
