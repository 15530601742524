import React, {Fragment} from "react";
import {Link, useLocation, useNavigate} from "react-router-dom";
import {withTranslation} from "react-i18next";
import Container from "react-bootstrap/Container";
import {Col, Row} from "react-bootstrap";
import iconExclam from "../../../files/img/profile/exclam.svg"
import './UserCoupons.css'
import Helper from "../../../helpers/Helper";
import UserCouponMini from "./UserCouponMini";

class UserCoupons extends React.Component {
	constructor(props) {
		super(props);
	}

	state = {
		coupons: {},
		loading: true
	};

	async getCoupons() {
		this.setState({loading: true})
		Helper.API.get("/profile/coupons").then((response) => {
			if (response.data.status) {
				this.setState({coupons: response.data.data})

			}
			this.setState({loading: false})
		}, (error) => {
			this.setState({loading: false})
		})
	}

	async componentDidMount() {
		this.getCoupons()
	}

	render() {
		return (
			<>
				<Container className={"page-profile-coupons"}>
					<Row>
						<Col md={12}>
							{Object.keys(this.state.coupons).map((key) => {
									var item = this.state.coupons[key]
									if (Object.keys(this.state.coupons).length > 0) {

										return (
											<UserCouponMini data={item}/>
										)
									}
								}
							)
							}

							{Object.keys(this.state.coupons).length == 0 &&
								<div className={"no-found"}>
									<div className={"up"}>
										<div className={"image"}><img srcSet={iconExclam}/></div>
										<div className={"text"}>У вас нет купонов</div>
									</div>
									<div className={"down"}>Вернуться к акциям</div>
								</div>
							}
						</Col>
					</Row>
				</Container>
			</>
		)
			;
	}
}

function WithNavigate(props) {
	return (
		<UserCoupons navigate={useNavigate()} location={useLocation()} {...props} />
	);
}

export default withTranslation()(WithNavigate);
