import React from "react";
import "./Footer.css";
import {Navbar,ButtonGroup,Button} from "react-bootstrap";
import Container from "react-bootstrap/Container";
import {useNavigate} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowLeft} from "@fortawesome/free-solid-svg-icons";
import cardIcon from "../../files/img/footer/card.svg"
import searchIcon from "../../files/img/footer/search.svg"
import mapIcon from "../../files/img/footer/map.svg"
import qrIcon from "../../files/img/footer/qr.svg"
import profileIcon from "../../files/img/footer/profile.svg"
import Helper from "../../helpers/Helper";

export function Header(data) {
	const navigate = useNavigate();
	const user_role = Helper.Cookies.get('user_role')
	return (
		<ButtonGroup className="footer-nav me-2" aria-label="First group">
			<Button variant="secondary" className={data.card ? 'active' : ''} onClick={(e)=>{navigate('/'+user_role)}}><img srcSet={cardIcon}/><div></div></Button>
			<Button variant="secondary" className={data.search ? 'active' : ''} onClick={(e)=>{navigate('/getcashback')}}><img srcSet={searchIcon}/><div></div></Button>
			<Button variant="secondary" className={data.map ? 'active' : ''} onClick={(e)=>{navigate('/map')}}><img srcSet={mapIcon}/><div></div></Button>
			<Button variant="secondary" onClick={()=>{ window.location.reload() }} className={data.qr ? 'active' : ''}><img srcSet={qrIcon}/><div></div></Button>
			<Button variant="secondary" onClick={()=>{
				navigate('/profile')
				//Helper.Cookies.clear();
				//window.location.href = '/'
			}} className={data.profile ? 'active' : ''}><img srcSet={profileIcon}/><div></div></Button>
		</ButtonGroup>
	)
}

export default Header;