import React, {Fragment} from "react";
import {Link, useLocation, useNavigate} from "react-router-dom";
import {withTranslation} from "react-i18next";
import {Button, Col, Container, Row, Form, Collapse, Spinner} from "react-bootstrap";
import Header from "../../components/design/Header";
import Helper from "../../helpers/Helper";
import FreecardLogo from "../../files/img/icons/freecard_authPage_logo.png";

import icon0 from "../../files/img/social/vk.png";
import icon1 from "../../files/img/social/facebook.png";
import icon2 from "../../files/img/social/ya.png";
import icon3 from "../../files/img/social/mail.png";
import icon4 from "../../files/img/social/google.png";
import icon5 from "../../files/img/social/ok.png";
import icon6 from "../../files/img/social/twitter.png";
import icon7 from "../../files/img/social/apple.png";
import Cookies from "js-cookie";

class Login extends React.Component {
	constructor(props) {
		super(props);
	}

	state = {
		ColOpen: false,
		form_email: '',
		form_password: '',
		valid: {
			form_email: false,
			form_password: false
		},
		invalid: {
			form_email: false,
			form_password: false
		},
		invalidText: {
			form_email: "",
			form_password: ""
		},
		isLoading: false
	};

	async getSocials(num) {
		//@TODO SocialAuth countrys
		return true;
	}

	async FormValidText(name, val) {
		var state = this.state.invalidText;
		state[name] = val
		this.setState({invalidText: state})
	}

	async FormValidState(name, val, text = "") {
		if (val) {
			this.FormValidText(name, "");
		}
		this.FormValidText(name, text)
		//var state = this.state.valid;
		//state[name] = val
		//this.setState({valid: state})
		var state = this.state.invalid;
		state[name] = (!val) ? true : false
		this.setState({invalid: state})
	}

	async LoginProcess() {
		var self = this;
		if (this.state.form_password.length < 3) {
			this.FormValidState('form_password', false, Helper.t("VALIDATOR.NOT_EMPTY"));
			return false;
		} else {
			this.FormValidState('form_password', true);
		}

		this.setState({isLoading: true})
		console.log({
			email: this.state.form_email,
			password: this.state.form_password
		});
		Helper.API.post('/auth', {
			email: this.state.form_email,
			password: this.state.form_password
		}).then((response) => {
			this.setState({isLoading: false})
			console.log(response);
			if (response && response.data && response.data.status && response.data.status === true) {
				Helper.Cookies.set('token', response.data.token)
				Helper.Cookies.set('user_role', "user")
				this.props.navigate('/')
			}
		}, (error) => {
			self.setState({isLoading: false})
			console.log("errorerrorerrorerrorerrorerror");
			console.log(error);
			console.log("errorerrorerrorerrorerrorerror");
			if (error.code === "ERR_NETWORK") {
				Helper.Alert(Helper.t("API_ERRORS.ERR_NETWORK"));
				return false;
			}
			if (error.response && error.response.status) {
				if (error.response.data && error.response.data.msg) {
					this.FormValidState('form_email', false, Helper.t("API_ERRORS." + error.response.data.msg));
					this.FormValidState('form_password', false, Helper.t("API_ERRORS." + error.response.data.msg));
					Helper.Alert(Helper.t("API_ERRORS." + error.response.data.msg));
				}
			}
		})
		return true;
	}

	async componentDidMount() {

	}

	render() {
		return (
			<>
				<Container>
					<Row className={''}>
						<Col md={12}>
							<div className={"pt-5 d-flex justify-content-center"}>
								<img className={'fadeInTop'} style={{width: '10rem', maxWidth: '300px'}}
									 srcSet={FreecardLogo}/>
							</div>

							<div className={"pt-5 d-flex justify-content-center"} style={{fontSize: '2rem'}}>
								{Helper.t('LoginPage.Вход')}
							</div>
							<Collapse in={(this.state.ColOpen ? false : true)}>
								<div>
									<div className={"pt-1 d-flex justify-content-center text-muted"}>
										{Helper.t('LoginPage.через')}
									</div>
									<div className={"pt-1"}>
										<Row>
											<Col md={12}
												 className={"d-flex pt-4 ps-5 pe-5 justify-content-center col-md-12"}
												 style={{flexWrap: "wrap"}}>
												<div className={(this.getSocials(0)) ? ' socialAuth' : 'd.none'}>
													<img srcSet={icon0}/>
												</div>
												<div className={(this.getSocials(5)) ? 'socialAuth' : 'd.none'}>
													<img srcSet={icon5}/>
												</div>
												<div className={(this.getSocials(4)) ? 'socialAuth' : 'd.none'}>
													<img srcSet={icon4}/>
												</div>
												<div className={(this.getSocials(2)) ? 'socialAuth' : 'd.none'}>
													<img srcSet={icon2}/>
												</div>
												<div className={(this.getSocials(7)) ? 'socialAuth' : 'd.none'}>
													<img srcSet={icon7}/>
												</div>
												<div className={(this.getSocials(3)) ? 'socialAuth' : 'd.none'}>
													<img srcSet={icon3}/>
												</div>
												<div className={(this.getSocials(1)) ? 'socialAuth' : 'd.none'}>
													<img srcSet={icon1}/>
												</div>
												<div className={(this.getSocials(6)) ? 'socialAuth' : 'd.none'}>
													<img srcSet={icon6}/>
												</div>
											</Col>
										</Row>
									</div>


									<div className={"pt-1 d-flex justify-content-center text-muted"}>
										{Helper.t('LoginPage.или')}
									</div>


									<div className={"pt-1 d-flex justify-content-center text-muted"}>
										<Form.Control
											readOnly={true}
											onClick={() => {
												this.setState({ColOpen: true})
											}}
											type="text"
											placeholder={Helper.t('LoginPage.Введите почту')}
										/>
									</div>
								</div>
							</Collapse>
							<Collapse in={this.state.ColOpen}>
								<div>
									<div className={"pt-1 d-flex justify-content-center text-muted"}>
										{Helper.t('LoginPage.используя')}
									</div>
									<div className={"pt-3"}>
										<Form.Control
											value={this.state.form_email}
											onChange={(e) => {
												this.setState({form_email: e.target.value})
											}}
											isInvalid={this.state.invalid.form_email}
											isValid={this.state.valid.form_email}
											type="text"
											placeholder={Helper.t('LoginPage.Введите почту')}
										/>
									</div>
									<div className={"pt-3"}>
										<Form.Control
											value={this.state.form_password}
											onChange={(e) => {
												this.setState({form_password: e.target.value})
											}}
											isInvalid={this.state.invalid.form_password}
											isValid={this.state.valid.form_password}
											type="text"
											placeholder={Helper.t('LoginPage.Введите пароль')}
										/>
										<Form.Control.Feedback type="invalid">
											{this.state.invalidText.form_password}
										</Form.Control.Feedback>
									</div>
									<div className={"pt-3 d-flex justify-content-center"}>
										<Button variant={"warning"} type="button" className={"ps-5 pe-5 fw-bolder"}
												disabled={this.state.isLoading}
												onClick={(e) => {
													if (!this.state.isLoading) {
														this.LoginProcess()
													}
												}}
										>
											{
												this.state.isLoading
													? <><Spinner size={"sm"}
																 animation="border"/> {Helper.t("Loading")}...</>
													: Helper.t('LoginPage.Войти')
											}


										</Button>
									</div>
								</div>
							</Collapse>
						</Col>
					</Row>
				</Container>
			</>
		);
	}
}

function WithNavigate(props) {
	return (
		<Login navigate={useNavigate()} location={useLocation()} {...props} />
	);
}

export default withTranslation()(WithNavigate);
