import React, {Fragment} from "react";
import {Link, useLocation, useNavigate} from "react-router-dom";
import {withTranslation} from "react-i18next";
import "./MarketingUserMini.css"
import {Button, Col} from "react-bootstrap";

import Moment from "react-moment";
class MarketingUserMini extends React.Component {
	constructor(props) {
		super(props);
	}

	state = {};

	async componentDidMount() {

	}

	render() {
		return (
			<>
				<Col xs={12} sm={12} md={12} lg={12} className={'mb-4 p-2 UserMarketingMini'}>
					<div className={"top"}>
						<div className={"str"}>
							<div className={"left"}>{this.props.data.job.name}</div>
							<div className={"right"}><Button size={"sm"} variant="primary">Детали</Button></div>
						</div>
						<div className={"str"}>
							<div className={"left"}>Вознаграждение: {this.props.data.job.cost}₽</div>
							<div className={"right"}></div>
						</div>
					</div>
					<div className={"bottom"}>
						<div className={"str"}>
							<div className={"left"}>Дата создания:</div>
							<div className={"right"}>{(this.props.data.created_at) ? <Moment format="DD.MM.YYYY HH:MM">{this.props.data.created_at}</Moment> : ''}</div>
						</div>
						<div className={"str"}>
							<div className={"left"}>Последнее обновление:</div>
							<div className={"right"}>{(this.props.data.updated_at) ? <Moment format="DD.MM.YYYY HH:MM">{this.props.data.updated_at}</Moment> : ''}</div>
						</div>
					</div>
				</Col>
			</>
		);
	}
}

function WithNavigate(props) {
	return (
		<MarketingUserMini navigate={useNavigate()} location={useLocation()} {...props} />
	);
}

export default withTranslation()(WithNavigate);
