import Helper from "../../helpers/Helper";

const FAVOURITES = 'FAVOURITES';
function favourites(favourites) {
	favourites = (favourites) ? favourites : {cashback:[],offers:[],insurance_offers:[],biz_offers:[],goods:[],action:[]};
	console.log('favouritesfavourites')
	console.log(favourites)
	console.log('favouritesfavourites')
	return {
		type: FAVOURITES,
		data: favourites,
	};
}


export {favourites,FAVOURITES};
