import React, {Fragment} from "react";
import {Link, useLocation, useNavigate} from "react-router-dom";
import {withTranslation} from "react-i18next";
import Container from "react-bootstrap/Container";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronRight, faAngleLeft, faBarsSort, faChevronUp, faAngleDown} from "@fortawesome/free-solid-svg-icons";
import {Button, Navbar, Offcanvas, Form, Collapse} from "react-bootstrap";
import "./Topmenu.css"
import bars from "../../../files/img/icons/bars.svg"
import Helper from "../../../helpers/Helper";

class Topmenu extends React.Component {
	constructor(props) {
		super(props);
		this.handleShow = this.handleShow.bind(this);
		this.handleClose = this.handleClose.bind(this);
		this.scrollTop = this.scrollTop.bind(this);
		this.document = document;
	}
	
	state = {
		topcity: "",
		show: false,
		showCity: false,
		searchCity: "",
		searchGood: "",
		searchBrand: "",
		cities: {},
		_goods: [],
		_brands: [],
		collapseGoodOpen: false,
		collapseBrandOpen: false,
		_complex: []
	};
	
	
	async scrollTop() {
		if (this.document.getElementById('offcanvas-body')) {
			this.document.getElementById('offcanvas-body').scrollTop = 0;
		}
		
		
	}
	
	async SearchSubmit() {
	
	}
	
	async getCities() {
		Helper.API.get('/assistant/cities/' + Helper.Cookies.get('country_default')).then((response) => {
			if (response.data.status) {
				var CitiesbyId = {};
				response.data.data.map((City) => {
					CitiesbyId[City.id] = City;
				})
				this.setState({cities: CitiesbyId});
			}
		})
	}
	
	async getComplex(city_id) {
		Helper.API.get('/assistant/complex/' + city_id).then((response) => {
			if (response.data.status) {
				this.setState({_complex: response.data.data})
			}
		}).catch(function (error) {
		
		})
	}
	
	async getGoods(data = false) {
		
		var url = '/catalog/goods/popular';
		if (this.state.searchGood != '' && this.state.searchGood.length >= 2) {
			url = url + "?search=" + encodeURI(this.state.searchGood)
		}
		Helper.API.get(url).then((response) => {
			if (response.data.status) {
				this.setState({_goods: response.data.data})
			}
		}).catch(function (error) {
		
		})
	}
	
	async getBrands(data = false) {
		
		var url = '/catalog/brands/popular';
		if (this.state.searchBrand != '' && this.state.searchBrand.length >= 2) {
			url = url + "?search=" + encodeURI(this.state.searchBrand)
		}
		console.log(this.state.searchBrand)
		Helper.API.get(url).then((response) => {
			if (response.data.status) {
				this.setState({_brands: response.data.data})
			}
		}).catch(function (error) {
		
		})
	}
	
	async handleClose() {
		this.setState({show: false})
		this.scrollTop();
	}
	
	async handleShow() {
		this.setState({collapseGoodOpen: false})
		this.setState({collapseBrandOpen: false})
		this.setState({showCity: false})
		this.setState({searchCity: ""})
		this.setState({topcity: Helper.Cookies.get('city_data')['name']})
		this.setState({show: true})
		this.getComplex(Helper.Cookies.get('city_data')['id']);
		this.setState({searchGood: ""})
		this.setState({searchBrand: ""})
		this.scrollTop();
		this.getGoods()
		this.getBrands()
		this.getCities();
	}
	
	async componentDidMount() {
	
	}
	
	render() {
		const country_data = Helper.Cookies.get('country_data')
		const menuData = [
			{'id': 0, 'name': 'Покупки онлайн', 'link': '/getcashback', 'active': true},
			{'id': 1, 'name': 'Бренды', 'link': 'brands'},
			{'id': 2, 'name': 'Деньги', 'link': 'debetcards'},
			{'id': 3, 'name': 'Заведения города', 'link': 'service'},
			{'id': 4, 'name': 'Кэшбэк по чеку', 'link': 'getcashbackoffline'},
			{'id': 5, 'name': 'Промокоды', 'link': 'promocodes'},
			{'id': 6, 'name': 'ОСАГО онлайн', 'link': '/osago', 'active': true},
			{'id': 7, 'name': 'Товары', 'link': '/goods', 'active': true},
			{'id': 8, 'name': 'Проверка чека', 'link': 'checks'},
			{'id': 9, 'name': 'Путешествия', 'link': 'travel'},
			{'id': 10, 'name': 'Страховка', 'link': 'osago'},
			{'id': 11, 'name': 'Бизнес', 'link': 'rko-banks'},
		];
		return (
			<>
				<Navbar style={this.props.style ? this.props.style : {}} expand="lg"
						className="bg-header bg-header-Topmenu">
					<Container className={'bg-topmenu-container'}>
						<div
							style={{left: '0', position: 'absolute', maxWidth: 'inherit', overflow: 'auto'}}
						>
							<div
								style={{position: 'relative'}}
							>
								<div className={'d-flex'}>
									
									<img srcSet={bars} style={{paddingLeft: '1rem'}} onClick={this.handleShow}/>
									
									
									{menuData.map((value) => {
										if (typeof (country_data.menu_items[value.id]) == 'string' || typeof (country_data.menu_items[value.id]) == 'number' && Number(country_data.menu_items[value.id]) == 1) {
											return (
												<Button onClick={(e) => {
													if (value.active) {
														this.props.navigate(value.link)
													}
												}} variant="secondary" className={"ms-1 me-1"}>{value.name}</Button>
											)
										}
									})}
								
								</div>
							</div>
						</div>
					</Container>
				</Navbar>
				<Offcanvas show={this.state.show} onHide={this.handleClose}>
					<Offcanvas.Header
						className={"p-2 text-center " + ((this.state.collapseGoodOpen || this.state.collapseBrandOpen) ? 'd-none' : '')}>
						<Offcanvas.Title onClick={(e) => {
							if (this.state.showCity) {
								this.setState({showCity: false})
								this.scrollTop();
							} else {
								this.setState({showCity: true})
							}
							
						}}>{this.state.topcity} <FontAwesomeIcon
							className={" " + ((this.state.showCity) ? "selectCityOpen" : "selectCityClose")}
							icon={faAngleDown}/></Offcanvas.Title>
					</Offcanvas.Header>
					<Offcanvas.Header className={"p-2 text-center " + ((this.state.collapseGoodOpen) ? '' : 'd-none')}
									  onClick={(e) => {
										  this.setState({collapseGoodOpen: false})
										  this.scrollTop();
									  }}>
						<FontAwesomeIcon style={{fontSize: "1.5rem"}}
										 icon={faAngleLeft}/><Offcanvas.Title> Товары </Offcanvas.Title>
					</Offcanvas.Header>
					<Offcanvas.Header className={"p-2 text-center " + ((this.state.collapseBrandOpen) ? '' : 'd-none')}
									  onClick={(e) => {
										  this.setState({collapseBrandOpen: false})
										  this.scrollTop();
									  }}>
						<FontAwesomeIcon style={{fontSize: "1.5rem"}}
										 icon={faAngleLeft}/><Offcanvas.Title> Бренды </Offcanvas.Title>
					</Offcanvas.Header>
					<Offcanvas.Body id={"offcanvas-body"}>
						<div className={"CitySelect " + ((this.state.showCity) ? 'd-block' : 'd-none')}>
							
							<Form.Group className="mb-3">
								<Form.Control className={"CitySearchInput"} placeholder={Helper.t("GOODS.Поиск")}
											  onKeyUp={(e) => {
												  this.setState({searchCity: e.target.value})
											  }}/>
							</Form.Group>
							{Object.keys(this.state.cities).map((key) => {
								var city = this.state.cities[key]
								if (this.state.searchCity == '' || city.name.toLowerCase().indexOf(this.state.searchCity.toLowerCase()) > -1) {
									return (
										<div className={'city pt-1 pb-1'} onClick={(e) => {
											Helper.Cookies.set('city_data', city)
											Helper.Cookies.set('city_default', key)
											Helper.API.post('/profile', {fields: {cityid: key}});
											this.getComplex(city.id);
											this.setState({topcity: city.name})
											this.setState({showCity: false})
											this.scrollTop();
										}}>{city.name}</div>
									)
								}
							})}
						
						</div>
						<div className={"MenuSelect " + ((this.state.showCity) ? 'd-none' : 'd-block')}>
							<div className={"GoodsMenu " + ((this.state.collapseGoodOpen) ? '' : 'd-none')}>
								<Form.Group className="mb-3">
									<Form.Control className={"CitySearchInput"} placeholder={Helper.t("GOODS.Поиск")}
												  onKeyUp={(e) => {
													  //if(e.code >=48 && e.code <= 90)
													  
													  this.setState({searchGood: e.target.value})
													  this.getGoods()
												  }}/>
								</Form.Group>
								{
									this.state._goods.map((good) => {
										return (
											<div className={"good"} onClick={(e) => {
												this.props.navigate("/goods?search=" + good.value + "&search_help=" + good.id, {state: {search: good.value}});
												this.handleClose()
											}}>{good.value}</div>
										)
									})
								}
							
							</div>
							<div className={"BrandsMenu " + ((this.state.collapseBrandOpen) ? '' : 'd-none')}>
								
								<Form.Group className="mb-3">
									<Form.Control className={"CitySearchInput"} placeholder={Helper.t("GOODS.Поиск")}
												  onKeyUp={(e) => {
													  //if(e.code >=48 && e.code <= 90)
													  
													  this.setState({searchBrand: e.target.value})
													  this.getBrands()
												  }}/>
								</Form.Group>
								{
									this.state._brands.map((brand) => {
										return (
											<div className={"good"} onClick={(e) => {
												this.props.navigate("/goods?brand=" + brand.id);
												this.handleClose()
											}}>{brand.vendor} ({brand.count_goods})</div>
										)
									})
								}
							</div>
							<div
								className={"OtherMenu " + ((this.state.collapseGoodOpen || this.state.collapseBrandOpen) ? 'd-none' : '')}>
								<div className={"GoodsLink"} onClick={(e) => {
									this.setState({collapseGoodOpen: true})
								}}>Товары <FontAwesomeIcon style={{fontSize: "1.5rem"}} icon={faChevronRight}/></div>
								<hr style={{margin: "7px 0 7px 0px"}}/>
								<div className={"GoodsLink pb-2"} onClick={(e) => {
									this.setState({collapseBrandOpen: true})
								}}>Бренды <FontAwesomeIcon style={{fontSize: "1.5rem"}} icon={faChevronRight}/></div>
								{this.state._complex.map((link) => {
									return (
										<div className={"complex-link mb-2"}>
											<div className={"image-circle p-1 me-1"}>
												<img className={""} style={{width: "20px", height: "20px"}}
													 srcSet={Helper.ResourceURL + link.img_url}/>
											</div>
											{link.name}</div>
									)
								})}
							</div>
						</div>
					</Offcanvas.Body>
				</Offcanvas>
			</>
		);
	}
}

function WithNavigate(props) {
	
	return (
		<Topmenu navigate={useNavigate()} location={useLocation()} {...props} />
	);
}

export default withTranslation()(WithNavigate);
