import React, {Fragment} from "react";
import {Link, useLocation, useNavigate} from "react-router-dom";
import {withTranslation} from "react-i18next";
import "./MarketingMini.css"
import {Button, Col} from "react-bootstrap";

import Moment from "react-moment";
import ExternalPage from "../../../components/Helper/ExternalPage";
import UserCashback from "../Cashback/UserCashback";
import MarketingJob from "./Job/MarketingJob";
class MarketingMini extends React.Component {
	constructor(props) {
		super(props);
		this.CloseJobPage = this.CloseJobPage.bind(this);
	}

	state = {
		JobPage:""
	};

	async componentDidMount() {

	}
	async CloseJobPage(update=false) {
		this.setState({JobPage: ''})
		if(update){
			this.props.callback_update()
		}
	}
	async OpenJobPage(data) {
		this.setState({JobPage: data})
	}

	render() {
		return (
			<>
				{this.state.JobPage}
				<Col xs={12} sm={12} md={12} lg={12} className={'mb-4 p-2 UserMarketingMini'}>
					<div className={"top"}>
						<div className={"str"}>
							<div className={"left"}>{this.props.data.name}</div>
							<div className={"right"}><Button size={"sm"} variant="warning"  onClick={(e) => {
								this.OpenJobPage(<ExternalPage className={"p-0"} title={"Задание"}  data={<MarketingJob callback={this.CloseJobPage} data={this.props.data}/>}
																	callback={this.CloseJobPage}/>)
							}}>Детали</Button></div>
						</div>
						<div className={"str"}>
							<div className={"left"}>Вознаграждение: {this.props.data.cost}₽</div>
							<div className={"right"}></div>
						</div>
					</div>
					<div className={"bottom"}>
						<div className={"str"}>
							<div className={"left"}>Дата создания:</div>
							<div className={"right"}>{(this.props.data.created_at) ? <Moment format="DD.MM.YYYY HH:MM">{this.props.data.created_at}</Moment> : ''}</div>
						</div>
						<div className={"str"}>
							<div className={"left"}>Последнее обновление:</div>
							<div className={"right"}>{(this.props.data.updated_at) ? <Moment format="DD.MM.YYYY HH:MM">{this.props.data.updated_at}</Moment> : ''}</div>
						</div>
					</div>
				</Col>
			</>
		);
	}
}

function WithNavigate(props) {
	return (
		<MarketingMini navigate={useNavigate()} location={useLocation()} {...props} />
	);
}

export default withTranslation()(WithNavigate);
