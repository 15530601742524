import React, {Fragment} from "react";
import {Link, useLocation, useNavigate} from "react-router-dom";
import {withTranslation} from "react-i18next";
import Footer from "../../components/design/Footer";
import {Map as AppleMap, Marker, Annotation} from 'mapkit-react';
import { useRef } from 'react';
import Helper from "../../helpers/Helper";
import "./Map.css"

class Map extends React.Component {
	constructor(props) {
		super(props);
	}
	
	state = {
		__startCoord: [55.7, 37.6],
		__DescClass: "d-none",
		__marks:{}
	};
	MapTo(latitude,longitude){
		this.MapRef().setCenterAnimated(new window.mapkit.Coordinate(latitude, longitude),true)
		if(this.MapRef().region.center.latitude.toFixed(10) !== latitude.toFixed(10) || this.MapRef().region.center.longitude.toFixed(10) !== longitude.toFixed(10)){
			this.MapTo(latitude,longitude);
		}else {
			this.MapRef().setCameraDistanceAnimated(1000,true)
		}
		
	}
	MapRef(){
		return this.props.mapRef.current;
		//return window.mapkit.maps[window.mapkit.maps.length-1]
	}
	async getData() {
	Helper.API.get('/map').then((response)=>{
		if(response.data.status){
			this.setState({__marks:response.data.data})
		}
	})
	}
	async setCoord() {
		var city_data = Helper.Cookies.get('city_data')
		var defCoord = [55.7, 37.6];
		if (city_data.latitude !== null && city_data.longitude !== null) {
			defCoord = [parseFloat(city_data.latitude), parseFloat(city_data.longitude)]
		}
		var coord = await Helper.ExternalStorage.GEO(defCoord);
		if(typeof (coord[0]) != 'number') return false;
		this.setState({__startCoord: coord})
		console.log("coord")
		console.log(coord)
		console.log("coord")
		this.MapRef().setCenterAnimated(new window.mapkit.Coordinate(coord[0],coord[1]))
		if(this.MapRef().region.center.latitude.toFixed(2) !== coord[0].toFixed(2) || this.MapRef().region.center.longitude.toFixed(2) !== coord[1].toFixed(2)){
			this.setCoord()
		}else{
			this.getData();
		}
	}
	
	async componentDidMount() {
	
	}
	
	render() {
		
		return (
			<>
				<div className={"OpenShorts p-2 ps-4 pe-4 " + (this.state.__DescClass)}>
					<div className={"data p-2"}>
					<div>Любой текст</div>
					</div>
				</div>

					<AppleMap
						ref={this.props.mapRef}
						onLoad={(e) => {
							console.log(this.props.mapRef)
							window.mapkit.language = "pl-PL"
							this.setCoord()
						}}
						showsUserLocation={true}
						showsUserLocationControl={true}
						initialRegion={{
							"centerLatitude": this.state.__startCoord[0],
							"centerLongitude": this.state.__startCoord[1],
							"latitudeDelta": 0.1,
							"longitudeDelta": 0.1
						}}
						isRotationEnabled={false}
						onRegionChangeEnd={(e) => {
						
						}}
						showsZoomControl={true}
						center={this.state.__startCoord}
						token="eyJhbGciOiJFUzI1NiIsInR5cCI6IkpXVCIsImtpZCI6Ijg4WjNTVTQ0Uk0ifQ.eyJpc3MiOiJRMzI5M1NONUg5IiwiaWF0IjoxNjkzMjE2Mjc2LCJleHAiOjE2OTU4MDcxMjgsIm9yaWdpbiI6Imh0dHA6Ly80NS44Ni4xODMuMjE6MzAwMCJ9.QZQOfgUPIbDn7vYDrxn0_CFcZnpH8VVGoqhZkw_djrMbJpxMyaOpoUDTdyUlBba902vBOeuRuKpR35e7L2XSIw">

						{Object.keys(this.state.__marks).map((key)=>{
							var mark = this.state.__marks[key]
							mark.longitude = parseFloat(mark.longitude)
							mark.latitude = parseFloat(mark.latitude)
							return (
								<Marker
									key={key}
									onSelect={(e) => {
										this.MapTo(mark.latitude, mark.longitude)
										this.setState({__DescClass: "fadeInTop"})
										
									}}
									clusteringIdentifier={"москва"}
									onDeselect={() => {
										this.setState({__DescClass: "fadeOutTop"})
									}} title={mark.nameMin} subtitle={"Это subtitle"}
									latitude={mark.latitude} longitude={mark.longitude}/>
							)
						})}
						
					</AppleMap>
<Footer map />
				
			</>
		);
	}
}

function WithNavigate(props) {
	const mapRef = useRef(null);
	return (
		<Map navigate={useNavigate()} mapRef={mapRef} location={useLocation()} {...props} />
	);
}

export default withTranslation()(WithNavigate);
