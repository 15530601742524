import React, {Fragment} from "react";
import {Link, useLocation, useNavigate} from "react-router-dom";
import {withTranslation} from "react-i18next";
import Helper from "../../../../helpers/Helper";
import GoodMini from "../../../../components/catalog/goods/GoodMini";

class FavActions extends React.Component {
	constructor(props) {
		super(props);
	}

	state = {
		goods: {}
	};

	async componentDidMount() {
		var fav = Helper.store.getState().favourites
		Helper.store.subscribe(() => {
			var fav = Helper.store.getState().favourites
			if (fav.goods) {
				var ids = [];
				//this.setState({goods: fav.goods})
				for (let item in fav.goods){
					ids.push(item)
				}
				if(ids.length>0){
					Helper.API.get('/catalog/goods?ids='+ids.join(','))
				}

			}
		})



	}

	render() {
		return (
			<>



			</>
		);
	}
}

function WithNavigate(props) {
	return (
		<FavActions navigate={useNavigate()} location={useLocation()} {...props} />
	);
}

export default withTranslation()(WithNavigate);
