import Helper from "../../helpers/Helper";

const ALERT_SHOW = 'ALERT_SHOW';
const ALERT_HIDE = 'ALERT_HIDE';
function alertShow(text,title=false,btns=[]) {
	title = (title) ? title : Helper.i18n.t('Ошибка!');
	return {
		type: ALERT_SHOW,
		text: text,
		title: title,
		btns: btns,
	};
}
function alertHide() {
	return {
		type: ALERT_HIDE
	};
}

export {alertShow,alertHide,ALERT_SHOW,ALERT_HIDE};
