import React, {Fragment} from "react";
import {Link, useLocation, useNavigate} from "react-router-dom";
import {withTranslation} from "react-i18next";

import {Form, Col} from "react-bootstrap";
import Helper from "../../../helpers/Helper";
import "./GoodsFilters.css"
import MultiRangeSlider from "../../../components/multiRangeSlider/MultiRangeSlider";
import PageSelect from "../../../components/Form/Select/PageSelect";
class GoodsFilters extends React.Component {
	constructor(props) {
		super(props);
	}
	
	state = {
		_shops:[],
		filter: {
			search: "",
			shop_id: 0,
			brand: 0,
			search_help:0
		}
	};
	async getShops(){
		Helper.API.get('/catalog/shops').then((response)=>{
			if(response.data.status){
				this.setState({_shops:response.data.data})
			}
		})
	}
	async componentWillUnmount() {
	
	}
	async componentDidMount() {
		this.getShops();
		this.setState({filter:this.props.filter})
	}
	
	render() {
		return (
			<>
				<Col xs={12} sm={12} md={12} lg={12} className={'mb-4 p-2 profile-page'}>
					<Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
						<Form.Label>Магазины</Form.Label>
						<Form.Select aria-label="Default select example">
							<option value="0">Все</option>
							{
								this.state._shops.map((option)=>{
									return(
										<option value={option.store_id}>{option.title}</option>
									)
								})
							}
							
						</Form.Select>
					</Form.Group>
					<MultiRangeSlider
						min={0}
						max={1000}
						onChange={({ min, max }) => console.log(`min = ${min}, max = ${max}`)}
					/>
				</Col>
				<PageSelect/>
			</>
		);
	}
}

function WithNavigate(props) {
	return (
		<GoodsFilters navigate={useNavigate()} location={useLocation()} {...props} />
	);
}

export default withTranslation()(WithNavigate);
