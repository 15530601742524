import React, {Fragment} from "react";
import {Link, useLocation, useNavigate} from "react-router-dom";
import {withTranslation} from "react-i18next";
import './Favourites.css'
import Helper from "../../helpers/Helper";
import store from "../../store";
import {favourites} from "../../reducers/Favourites/Init";

class Favourites extends React.Component {
	constructor(props) {
		super(props);
		this.state.right = (props.right) ? props.right : '0.4rem';
		this.state.top = (props.top) ? props.top : '0.4rem';
	}

	state = {
		active: false,
		await: false,
		right:'0.4rem',
		top:'0.4rem'
	};

	async changeFav() {
		if (this.state.await) {
			return false;
		}
		var value = (this.state.active) ? 0 : 1;

		this.setState({await: true})
		Helper.API.post("/profile/favourites", {
			id: this.props.id,
			type: this.props.type,
			value: value
		}).then((response) => {
			if (this.state.active) {
				this.setState({active: false})
			} else {
				this.setState({active: true})
			}
			this.setState({await: false})
			Helper.UpdateFavourites()
		}).catch(function (error) {
			this.setState({await: false})
			Helper.UpdateFavourites()
		})
		Helper.UpdateFavourites()
	}

	async componentDidMount() {
		var fav = Helper.store.getState().favourites
		Helper.store.subscribe(() => {
			var fav = Helper.store.getState().favourites
			if (fav && fav[this.props.type] && fav[this.props.type][this.props.id]) {
				this.setState({active: true})
			} else {
				this.setState({active: false})
			}
		})
		if (fav && fav[this.props.type] && fav[this.props.type][this.props.id]) {
			this.setState({active: true})
		} else {
			this.setState({active: false})
		}
	}

	render() {

		return (
			<>
				<div id={"heart_fav_"+this.props.id} className={"heart_fav " + ((this.state.active) ? 'heart_fav_active' : '')}
					 style={{right:this.state.right,top:this.state.top}}
					 onClick={(e) => {
					if (this.state.await) {
						return false;
					}
					if (Helper.Cookies.get('user_role') == 'guest') {
						Helper.Alert(Helper.t('favourites.Для добавления в избранное авторизуйтесь'))
						return false;
					}
					this.changeFav()

				}}>
					<svg viewBox="0 0 14 13" fill="none"
						 xmlns="http://www.w3.org/2000/svg">
						<path
							d="M6.75583 12.4007L5.77623 11.5087C2.29698 8.35274 0 6.26455 0 3.71683C0 1.62865 1.63491 0 3.71571 0C4.89122 0 6.01944 0.547388 6.75583 1.40564C7.49222 0.547388 8.62044 0 9.79595 0C11.8767 0 13.5117 1.62865 13.5117 3.71683C13.5117 6.26455 11.2147 8.35274 7.73543 11.5087L6.75583 12.4007Z"
							fill="#FF5E5E"/>
					</svg>
				</div>
			</>
		);
	}
}

function WithNavigate(props) {
	var favourites = Helper.store.getState().favourites
	Helper.store.subscribe(() => {
		favourites = Helper.store.getState().favourites

	})

	return (
		<Favourites navigate={useNavigate()} location={useLocation()} {...props} favourites={favourites}/>
	);
}



export default withTranslation()(WithNavigate);
