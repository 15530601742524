import React, { Fragment } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {withTranslation} from "react-i18next";
import {Button, Col, Card, InputGroup, Form, Spinner} from "react-bootstrap";
import ExternalPage from "../../../../components/Helper/ExternalPage";
import Helper from "../../../../helpers/Helper";

class MarketingReport extends React.Component {
	constructor(props) {
		super(props);
	}
	state = {
		sendMsgBtnActive:true,
		msg:""
	};
	async sendDone() {
		this.setState({sendMsgBtnActive: false})
		Helper.API.post("/profile/marketing/task/done/" + this.props.data.id, {msg: this.state.msg}).then((response) => {
			if (response.data.status) {
				Helper.Alert(response.data.data,"")
				this.props.callback(true);
			}else{
				Helper.Alert(response.data.data)
			}
			this.setState({sendMsgBtnActive: true})
		}).catch(function (error) {
			this.setState({sendMsgBtnActive: true})
		})
	}
	async componentDidMount() {
	
	}
	render() {
		return (
			<>
				<Col xs={12} sm={12} md={12} lg={12} className={'mb-4 p-0 UserMarketingJob'}>
					<div className={"pall text-center p-2"}>Отправить отчет по заданию</div>
					
					<div className={"stext ps-3 pe-3 pb-1"}>
						Задание<br/>
						<Card>
							<Card.Body dangerouslySetInnerHTML={{__html: this.props.data.detail}}/>
						</Card>
					</div>
					<div className={"stext  ps-3 pe-3 pb-1"}>
						<span style={{fontSize:"13px"}}>Ваш отчет по заданию</span>
					</div>
					<div className={"stext  ps-3 pe-3 pb-1"}>
						Сопровождайте ваши отчеты ссылками с вашими выполненными заданиями
					</div>
					<div className={"stext  ps-3 pe-3 pb-1"}>
						<Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
							<Form.Control onChange={(e) => {
								this.setState({msg: e.target.value})
							}} value={this.state.msg} as="textarea" rows={3} />
						</Form.Group>
					</div>
					<div className={"stext btn-wrap p-2"}>
						<Button className={"btn-start"} disabled={!this.state.sendMsgBtnActive} variant={"warning"} onClick={(e) => {
							this.sendDone();
						}}>{this.state.sendMsgBtnActive
							? <>Выполнить задание</>
							: <Spinner
								as="span"
								animation="border"
								size="sm"
								role="status"
								aria-hidden="true"
							/>
						}</Button>
					</div>
				</Col>
			</>
		);
	}
}
function WithNavigate(props) {
	return (
		<MarketingReport navigate={useNavigate()} location={useLocation()} {...props} />
	);
}
export default withTranslation()(WithNavigate);
